@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.shares-distribution-wrapper{
  .shares-distribution-select-group {
    width: 50%;
    max-width: 350px;
    margin-bottom: 10px;
  }
  .shares-distribution-table{
    display: flex;
    border: 1px solid $borderGrey;
    .shares-distribution-table-left{
      flex: 0 225px;
      box-shadow: 6px 0 10px 0px #72727226;
      .shares-distribution-table-cell{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 225px;
        text-align: left;
        &:not(:first-child){
          cursor: pointer;
        }
      }
    }
    .shares-distribution-table-right{
      flex: 1 0px;
      overflow-x: auto;
      .shares-distribution-table-row{
        display: flex;
        .shares-distribution-table-cell{
          min-width: 140px;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          border-width: 0 0 1px 1px;
          border-style: solid;
          border-color: #ccc !important;
          text-align: right;
        }
        .output_wrapper{
          margin: 0;
          padding: 0;
          .output_value {
            padding: 15px;
            height: 51px;
            border-radius: 0;
            background-color: var(--primary-light-color);;
          }
        }
        &.total {
            .output_value .value {
              font-weight: bold !important;
            }
          }
      }
    }

    .shares-distribution-table-cell{
      padding: $boxPadding;
      border-bottom: 1px solid #ccc;
    }
    h6.shares-distribution-table-cell{
      background-color: $lightGrey;
      font-size: 14px;
      border: none !important;
      text-align: right;
      margin: 0;
    }
  }
}

@media (max-width: 440px) {
    .shares-distribution-table-left{
    max-width: 135px;
    .shares-distribution-table-cell{
      max-width: 135px;
    }
  }
}
