@import 'src/styles/breakpoints';
@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';

  .reporting-row{
    display: flex;
    margin-bottom: $marginListBetween;
    padding: $boxPadding;
    color: $text-color;
    justify-content: space-between;
    align-items: stretch;
    box-shadow: $boxShadowMedium;
    &:hover{
      background-color: var(--primary-color-15);
      color: $textColor;
    }
    .reporting-info{
      padding: 0 15px;
      *{
        color: $text-color;
      }
    }
    .reporting-actions{
      flex: 0 0 270px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $greyLight;
      .buttons{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .report-icon {
          cursor: pointer;
          color: $svgColor;
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

  .reportings-empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0;
    position: relative;
    img{
      max-width: 75%;
      height: auto;
      max-height: 300px;
      margin-bottom: 25px;
      position: relative;
      z-index: 2;
    }
    img.arrow-search{
      position: absolute;
      top: -50px;
      right: 100px;
      z-index: 1;
    }
  }
}

@media (max-width: 650px){
  .reporting-row{
    flex-direction: column;
    & > *{
      width: 100%;
    }
    .reporting-info{
      padding: 0;
    }
    .reporting-actions{
      flex: auto;
      border-left: none !important;
      border-top: 1px solid $greyLight;
      padding-top: 15px;
      max-width: 600px;
    }
  }
  .reportings-empty{
    img.arrow-search{
      display: none;
    }
  }
}
