@import 'src/styles/_variables';
@import 'src/styles/_mixins';
@import 'src/styles/_breakpoints';
@import 'src/styles/colors';
.firstStep {
  height: 100vh;
}
.onBoarding {
  width: 100%;
  .onboarding-header {
    padding: 0px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .closeOnboarding {
      cursor: pointer;
    }
    svg {
      cursor: pointer;
      color: var(--primary-color);
    }

    .onboarding-header--logout {
      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid transparent;
      line-height: 1;
      color: var(--primary-color);
      span {
        font-size: 16px;
      }
      &:hover {
        border-bottom: 1px solid var(--primary-color);
      }
    }
  }

  .onboarding-wrapper {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .step-title{
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    .step-message{
      text-align: center;
      margin-bottom: 25px;
    }
    .step-svg {
      max-width: 100%;
      margin: 25px 0px;
      height: 180px;
    }

    .onboarding-next-step {
      padding: 15px 0px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .onboarding-form-wrapper {
        background-color: $white;
        box-shadow: $boxShadow;
        border-radius: 15px;
        padding: 25px;
        width: $breakpoint-md;
        max-width: 100%;
        form {
          .buttons {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            button:only-child {
              margin-left: auto;
            }
          }
        }
      }
      .progress-bar {
        width: 100%;
        height: 28px;
        border-radius: 50px;
        background-color: #efefef;
        margin-bottom: 50px;
        .progress {
          width: 100%;
          height: 28px;
          border-radius: 50px;
          background-color: var(--primary-color);
        }
      }
    }
    .onboarding-step-bg {
      h1 {
        color: $white !important;
      }
      h3 {
        color: $white !important;
      }
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90vh;
        background-color: var(--primary-color);
        border-radius: 0 0 20vw 0;
        z-index: -1;
      }
    }
    .onboarding-step {
      text-align: center;
      width: 100%;
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 90vh;
        width: auto;
        z-index: -2;
        #Rectangle-2 {
          fill: var(--primary-color);
        }
      }
      h1 {
        margin-bottom: 15px;
        font-weight: bold;
      }

      h3 {
        margin-bottom: 50px;
      }
    }
  }
}

@media (min-width: $breakpoint-lg){
  .onBoarding {
    .onboarding-wrapper {
      .onboarding-next-step {
        .onboarding-form-wrapper {
          padding: 40px 120px;
        }
      }
    }
  }
}


@media (max-width: $breakpoint-sm){
  .onBoarding {
    .onboarding-wrapper {
      .onboarding-next-step {
        .onboarding-form-wrapper {
          .buttons {
            flex-direction: column;
            * {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

