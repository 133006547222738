@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.impersonate-bar {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: red;
  color: white;
  height: 40px;
  z-index: 10;
  & > * {
    font-weight: 600;
  }
  .impersonate-bar--button {
    border-radius: $borderRadiusLight;
    border: 1px solid white;
    padding: 0px 5px;
    cursor: pointer;
    margin-left: 20px;
  }
}

@media (max-width: $breakpoint-md) {
  .impersonate-bar {
    .impersonate-bar--email {
      display: none;
    }
  }
}