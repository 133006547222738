@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.performance-wrapper{
  .shadow-light{
    box-shadow: $boxShadowMedium;
  }
  .toggle-table {
    border-radius: $borderRadiusLight;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $boxShadowLight;
    padding: 10px 20px;
    position: absolute;
    top: 75px;
    .input_toggle {
      margin: 0;
      width: 50px;
    }
  }

  .toggle-edit {
    border-radius: $borderRadiusExtraLight;
    border: 1px solid $borderGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    height: 40px;
    svg {
      font-size: 26px
    }
    .input_toggle {
      justify-content: center;
      margin: 0;
    }
  }
}

.kpi-relation-box{
  background-color: white;
  box-shadow: $boxShadow;
  padding: $boxPadding;
  margin-bottom: $marginListBetween;
  border-radius: $borderRadius;
  &.clickable{
    cursor: pointer;
  }
  h4{
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: flex-start;
    svg{
      margin-top: 3px;
      margin-right: 5px;
      color: var(--primary-color);
    }
    &.clickable {
      svg{
        cursor: pointer;
      }
    }
  }
  &.saving{
    h4{
      svg{
        opacity: 0.5;
        cursor: progress;
      }
    }
  }
  .kpi-value{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    h3{
      font-weight: bold;
      margin: 0;
    }
    .kpi-arrow{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      span{
        font-size: 16px;
        padding: 0 5px;
        color: #2E2E2E;
      }
    }
  }
  .kpi-date{
    color: var(--primary-color);
  }
}

.kpi-relation-card {
  position: relative;
  padding: 15px 20px 0;
  margin: 5px;
  margin-right: 10px;
  box-shadow: $boxShadowLight;
  border-radius: $borderRadius;
  background-color: white;
  width: 97%;
  height: 215px;
  cursor: pointer;
  canvas {
    cursor: pointer;
  }
  .kpi-relation-card-name {
    width: 90%;
    @include textEllipsis;
    margin: 0;
    font-size: 18px;
  }
  .kpi-relation-card-value {
    width: 90%;
    @include textEllipsis;
    .value {
      font-weight: 700;
      font-size: 30px;
      margin: 0;
      line-height: 35px;
    }
  }
  .kpi-graph {
    position: relative;
    top: -45px;
  }
  .kpi-relation-card-menu {
    position: absolute;
    top: 17px;
    right: 15px;
  }
  .kpi-relation-card-date {
    position: absolute;
    bottom: 5px;
    color: $textColorLight;
    right: 20px;
  }
}

.table_kpi .table-rows {
  svg.disabled {
    color: $darkGray;
  }
}

.kpi-relation-details{
  .krd-columns{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .krd-column-left{
      flex: 1 0px;
      .kpi-relation-box{
        max-width: 350px;
      }
    }
    .krd-column-right{
      display: flex;
      flex-direction: column;
      button:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
  .krd-info{
    margin-bottom: $spacing;
  }
  .krd-form{
    margin-bottom: $spacing;
    .krd-form-fields{
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      & > *{
        padding: 10px 5px;
        &:not(:last-child){
          flex: 1 0px;
        }
      }
      .button-app {
        margin-bottom: 5px;
      }
      .input_number,
      .input_select,
      .input_date,
      .input-select-wrapper,
      .input_permission,
      .input_text{
        margin-bottom: 0;
      }
    }
    .krd-form-notes {
      overflow: hidden;
      max-height: 500px;
      transition: max-height 0.6s;
      span {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      &.collapse-notes {
        transition: all 0.2s;
        max-height: 20px;
        transition: max-height 0.3s;
      }
      .notes-icon {
        padding-right: 5px;
        transition-duration: 0.3s;
        transition-property: transform;
        &.open {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.kpi-relation-graph{
  .krg-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $spacing;
    .krg-mode{
      display: flex;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      & > div{
        padding: 15px 25px;
        cursor: pointer;
        svg{
          color: var(--primary-color);
        }
        &.active{
          background-color: var(--primary-color);
          svg{
            color: #fff;
          }
        }
        &:first-child{
          border-radius: $borderRadius 0 0 $borderRadius;
        }
        &:last-child{
          border-radius: 0 $borderRadius $borderRadius 0;
        }
      }
    }
    .krg-dates{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      & > div:not(:first-child) {
        margin-left: 10px;
      }
      .input_text{
        margin: 0;
      }
      .pre_text,
      .post_text{
        display: none;
      }
    }
  }
  .krg-empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      margin: 15px 0 0 0 ;
    }
    img{
      max-width: 250px;
    }
  }
}

.performancePending-wrapper{
  .progress-bar-wrapper{
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 24px;
    .progress-bar-component{
      transition: width 0.3s;
      width: calc(100% / 3);
    }
  }
}

.edit-kpi-value {
  .krd-form-notes {
    overflow: hidden;
    max-height: 500px;
    transition: max-height 0.6s;
    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &.collapse-notes {
      transition: all 0.2s;
      max-height: 20px;
      transition: max-height 0.3s;
    }
    .notes-icon {
      padding-right: 5px;
      transition-duration: 0.3s;
      transition-property: transform;
      &.open {
        transform: rotate(90deg);
      }
    }
  }
}

.kpi-diff {
  font-size: 14px;
  svg {
    margin-right: 3px;
    font-size: 14px;
  }
}

.performanceFill-wrapper{
  padding: 0 10px;
  .kpi-pager-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    .kpi-pager{
      flex: 1;
      display: flex;
      align-items: center;
      gap: 20px;
      .kp-year{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        border-radius: $borderRadiusExtraLight;
        border: 1px solid $borderGrey;
        height: 40px;
        .input_number{
          width: 60px;
          margin: 0;
          .pre_text, .input-step {
            display: none;
          }
          input {
            text-align: center;
            border: none;
            height: 25px;
          }
        }
        .output_wrapper {
          margin: 0;
          .output_value {
            padding: 2px 10px;
            border: none;
            display: flex;
            align-items: center;
            line-height: 1;
          }
        }
        svg{
          cursor: pointer;
        }
      }
    }
  }
  .performance-legend {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    gap: 15px;
    .performance-legend-item {
      display: flex;
      align-items: center;
      gap: 5px;
      .performance-legend--circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      .performance-legend--name {
        font-weight: bold;
        cursor: pointer;
        &.disabled {
          color: $svgColor;
        }
      }
    }
  }
  
  .kpi-table{
    display: flex;
    border: 1px solid $borderGrey;
    border-right: none !important;
    .kpi-table-left{
      border-right: 1px solid $borderGrey;
      box-shadow: 6px 0 10px 0px #72727226;
      z-index: 1;
      .kpi-table-cell {
        width: calc(100% - 38px);
        display: flex;
        align-items: center;
        .kpi-name {
          @include textEllipsis;
          width: calc(100% - 35px);
          text-align: left;
          cursor: pointer;
        }
      }
      h6.kpi-table-cell {
        width: 100%;
      }
      .kpi-row-header {
        width: 250px;
        min-height: 55px;
        position: relative;
        display: flex;
        align-items: center;
        padding: $boxPadding;
        border-bottom: 1px solid $borderGrey;
        &.monthly {
          width: 250px;
          .kpi-table-cell.xl {
            width: 100%;
            line-height: 79px;
          }
        }
        .kpi-aggregate {
          @include keywords;
          flex: 0 0 40px;
          overflow: hidden;
          height: 100%;
          div {
            display: flex;
            align-items: center;
            height: 21px;
            padding: 0px 9px;
            font-size: 10px;
          }
        }
        .kpi-row-tag {
          position: absolute;
          top: 0px;
          right: 10px;
          height: 55px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 45px;
          span {
            height: 10px;
            width: 10px;
            border-radius: 50%;
          }
          &.xl {
            height: 110px;
          }
        }
      }
    }
    .kpi-table-right{
      flex: 1 0px;
      overflow-x: auto;
      .kpi-table-row{
        display: flex;
        .kpi-table-cell{

          text-align: right;
          &.input_number {
            width: 100%;
            margin: 0;
            .pre_text {
              display: none;
            }
            .input {
              padding-right: 23px !important;
              height: 55px;
              text-align: right;
              &:not(:focus) {
                border-left: 0px;
                border-top: 0px;
                border-radius: 0;
              }
            }
            .input-step {
              display: none;
            }
            .input-symbol {
              right: 11px;
            }
          }
          &.output_wrapper {
            margin-bottom: 0;
            width: 100%;
            .output_value {
              height: 55px !important;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 15px 10px;
              position: relative;
              border-left: 0px;
              border-top: 0px;
              border-radius: 0;
              .value {
                width: 100%;
                display: inline-block;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
          &.presave input {
            background-color: #fff3cd !important;
          }
        }
        input{
          outline: none;
          &:disabled{
            background-color: #f7f7f7;
            cursor: not-allowed;
          }
        }
      }
    }

    h6.kpi-table-cell{
      background-color: $lightGrey;
      border-bottom: 1px solid $borderGrey;
      margin: 0;
      padding: $boxPadding;
    }
  }
}


@media (max-width: $breakpoint-lg){
  .performance-wrapper{
    .summary{
      .columns{
        .column {
          min-height: calc(#{$toolViewHeight} - #{$spacing} - 65px);
        }
      }
      .column-buttons{
        align-self: flex-start;
        order: -1;
        flex: 0 100%;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: $spacing;
        padding-left: 0px;
        & > * {
          margin-bottom: 0 !important;
          width: auto !important;
          &:last-child{
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: $breakpoint-md){
  .performance-wrapper{
    .summary{
      .columns{
        flex-direction: column;
        .column{
          height: auto;
          min-height: auto;
          margin: 0 0 $marginListBetween 0;
          div.kpis-list{
            overflow-y: auto;
            padding: 10px;
            max-height: 50vh;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            & > div{
              flex: 0 calc(50% - 20px);
              margin: 10px;
            }
          }
        }
      }
    }
  }
  .performancePending-wrapper{
    .row:not(:last-child){
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;
    }
    .progress-bar-wrapper{
      .progress-bar-component{
        width: 50%;
      }
    }
  }
  .performanceFill-wrapper{
    padding-top: 20px;
  }
  .kpi-pager-header {
    flex-direction: column;
    .input-select-wrapper {
      flex: auto;
    }
  }
  .toggle-table {
    position: relative !important;
    top: 0 !important;
    width: 130px;
  }
}

@media (max-width: $breakpoint-sm){
  .performance-wrapper{
    .kpi-relation-details{
      .krd-form{
        .krd-form-fields{
          flex-direction: column;
          & > *{
            width: 100%;
            &:last-child{
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }

  .kpi-relation-details{
    .krd-columns{
      display: flex;
      flex-direction: column;
      & > *{
        flex-basis: 100%;
        width: 100%;
      }
      .krd-column-left{
        .kpi-relation-box{
          max-width: 100%;
        }
      }
      .krd-column-right{
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        button:not(:last-child){
          margin: 10px;
        }
        margin: $spacing 0;
      }
    }
  }
  .performancePending-wrapper{
    .progress-bar-wrapper{
      .progress-bar-component{
        width: 100%;
      }
    }
  }
  .kpi-row-header {
    max-width: 135px;
  }
}