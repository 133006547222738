@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

$transition: 0.2s;

.messages{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: $transition;
  &.writing{
    &.messages-list-chat{
      height: 100%;
    }
  }
  .new-message{
    background-color: white;
    position: relative;
    padding: 0 18px;
    .input{
      border-radius: $borderRadiusMedium;
      box-sizing: border-box;
      border: 1px solid $borderGrey;
      margin: 0;
      .input_textarea{
        margin: 0px;
        .pre_text{
          margin: 0px
        }
        .textarea{
          height: 42px !important;
          background-color: white;
          border: 0.5px solid $borderDarkGrey;
          padding: 11px 25px ;
          overflow: hidden;
          border:none;
          transition: $transition;
          resize: none;
        }
      }
    }
    .avatar-img{
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      left: 0px;
      height: fit-content;
    }
    .attach-button,
    .send-button {
      height: fit-content;
      position: absolute;
      cursor: pointer;
      top: 0px;
      bottom: 0px;
      margin: auto;
      transition: $transition;
    }
    .send-button {
      right: 0px;
      .button-app{
        padding: 0;
        min-width: 0px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }
    }
    .attach-button{
      right: 40px;
      color: var(--primary-color);
      display: flex;
      align-items: center;
    }
    &.new-message-focus{
      &.background{
        background-color: inherit;
      }
      .input{
        .textarea{
          height: 100px !important;
        }
      }
      .send-button,
      .attach-button{
        top: unset;
        margin: 0;
      }
      .send-button {
        bottom: 5px;
      }
      .attach-button{
        bottom: 10px;
      }
    }

    &.loading{
      cursor: progress;
      .input{
        background-color: #f7f7f7 !important;
        .tag{
          svg{
            display: none;
          }
        }
      }
    }
  }
  .messages-list{
    overflow-y: auto;
    &.messages-list-chat{
      height: 100%;
      padding: 0px 3px;
    }
    &.messages-list-post{
      height: 100%;
      padding: $spacing 3px 0 3px;
      flex-direction: column-reverse;
      .message-wrap{
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .unread-mark{
      position: relative;
      height: 20px;
      display: flex;
      flex: 1;
      align-items: center;
      margin-bottom: 15px;
      .unread-text{
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 80px);
        color:var(--primary-color);
        background-color: white;
        z-index: 1;
      }
      .unread-line{
        z-index: 0;
        flex-grow: 1;
        height: 1px;
        background-color: var(--primary-color);
        border: none;
      }
    }
    .message{ 
      border-radius: $borderRadius;
      box-shadow: $boxShadowLight;
      padding-top: 15px;
      margin-bottom: $marginListBetween;
      position: relative;
      overflow: hidden;
      margin-bottom: $marginListBetween;
      .form-container{
        position: relative;
        height: 140px;
      }
      .message-header{
        padding-left: 15px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        h6{
          font-size: 14px;
        }
        .date{
          color: var(--primary-color);
          font-size: 12px;
        }
        .unread{
          position: absolute;
          right: 30px;
          top: 30px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--primary-color);
        }
      }
      .message-body{
        padding: 0px 15px 10px;
        .message-text{
          display: inline-block;
          margin-bottom: 10px;
        }
        .read-more{
          cursor: pointer;
          margin-left: 10px;
          color: var(--primary-color);       
        }
        .buttons{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          & > *{
            display: flex;
            align-items: center;
          }
          .not-read{
            flex: 1 0px;
            display: flex;
            align-items: center;
            svg{
              margin-right: 5px;
              width: 14px;
              height: auto;
              color: var(--primary-color);  
            }
          }
          .show-replies{
            display: flex;
            align-items: center;
            .show-replies-number{
              color:white;
              font-size: 11px;
              text-align: center;
              width: 20px;
              position: relative;
              left: 24px;
              margin: 0 0 0 -20px;
            }
          }
          .show-replies,
          .reply{
            margin-left: 10px;
            color: var(--primary-color);
            cursor: pointer;
            &.reply-disabled{
              pointer-events: none;
              color: grey
            }
            svg{
              margin: 0 5px;
            }
          }
          svg{
            height: 18px;
            width: auto;
          }
        } 
        .horizontal-bar {
          display: block;
          width: 100%;
          margin: auto;
          margin: 2px;
          margin-bottom: 15px;
          border-bottom: 1px solid $borderDarkGrey;
          &.light {
            border-bottom: 1px solid $borderLightGrey;
          }
        } 
      }
      .load-more{
        cursor: pointer;
        color: var(--primary-color);
        text-align: center;
      }
      .replies-number{
        color: var(--primary-color);
        margin-left: 20px;
      }
      &.message-reply {
        border-radius: 0;
        box-shadow: none;
        padding: 15px 0px 0px 15px;
        margin-bottom: 0;
        &:not(:last-child){
          &::after{
            display: block;
            content: '';
            width: calc(100% - 30px);
            border-bottom: 1px solid #ccc;
            position: absolute;
            left: 15px;
            bottom: 0;
          } 
        }  
        &:last-child{
          padding-bottom: 10px;
        }  
      }
    }
    .message-loader{
      padding: 15px;
      margin-bottom: $marginListBetween;
      & > div:first-child{
        display: flex;
        align-items: center;
        & > *:first-child{
          margin-right: 5px;
          width: 35px;
        }
      }
    }
  }
  .pages-control{
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 10px;
    .pages-control-pointer{
      cursor: pointer;
    }
  }
  &.reply-container-background{
    background: var(--primary-light-color);
  }

  .tag {
    display: inline-flex;
    align-items: center;
    color:black;
    background-color: #f7f7f7;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 20px;
    margin: 5px 0px 5px 5px;
    .file-name{
      color: black;
      font-size: 14px;
    }
    .remove {
      cursor: pointer;
      position: relative;
      left: 5px;
      margin-left: 5px;

    }
  }  
}

@media (max-width: $breakpoint-lg) {
}