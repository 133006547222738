@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.alert-component {
  border-radius: $borderRadius;
  padding: $boxPadding;
  margin-bottom: 20px;
  width: 100%;
  justify-content: flex-start;
  svg{
    margin-right: 10px;
    width: 18px;
    height: auto;
  }
  &.alert-info,
  &.alert-primary{
    svg{ color: var(--primary-color) }    
    background-color: var(--primary-light-color);
  }
  &.alert-success{
    svg { color: #155724 };
    background-color: #d4edda;
    border-color: var(--success-color);    
  }
  &.alert-warning{
    svg{ color: #856404 }
    background-color: #fff3cd;
  }
  &.alert-danger{
    svg{ color: $red }
    background-color: #FFE3E7;
  }
  &.pointer {
    cursor: pointer;
  }
  h5{
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0;
  }
}
