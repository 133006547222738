@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.PerformanceComparator-wrapper{
  .performance-Comparator-legend {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    .performance-legend-item {
      display: flex;
      align-items: center;
      gap: 5px;
      .radio-custom {
        width: 15px;
        height: 15px;
      }
      .performance-legend--name {
        font-weight: bold;
        cursor: pointer;
        &.disabled {
          color: $svgColor;
        }
      }
    }
  }
  .kpi-table{
    display: flex;
    .kpi-table-left{
      box-shadow: 6px 0 10px 0px #72727226;
      .kpi-table-cell {
        justify-content: flex-start;
        position: relative;
        min-height: 55px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 250px;
        .kpi-name {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 180px;
        }
        .kpi-tag {
          position: absolute;
          height: 55px;
          right: 5px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 22px;
        }
        .kpi-aggregate {
          @include keywords;
          flex: 0 0 40px;
          overflow: hidden;
          height: 24px;
          div {
            padding: 4px 9px;
            font-size: 10px;
          }
        }
        &.monthly {
          &.xl {
            line-height: 89px;
            .kpi-tag {
              height: 110px;
            }
          }
        }
        &:not(:first-child){
          cursor: pointer;
        }
      }
    }
    .kpi-table-right{
      flex: 1 0px;
      overflow-x: auto;
      .kpi-table-row{
        display: flex;
        .kpi-table-cell{
          min-width: 150px;
          min-height: 55px;
          width: 100%;
          border-width: 0 0 1px 1px;
          border-style: solid;
          border-color: #ccc !important;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          padding: 4px 10px;
        }
        div.disabled{
          background-color: var(--primary-light-color);
        }
      }
    }

    .kpi-table-cell{
      padding: 10px;
      border-bottom: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    h6.kpi-table-cell{
      background-color: var(--primary-color);
      color: #fff;
      border: none !important;
      justify-content: flex-end;
      margin: 0;
      min-height: 50px !important;
    }
  }

  .krg-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $spacing;
    .krg-mode{
      display: flex;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      & > div{
        padding: 15px 25px;
        cursor: pointer;
        svg{
          color: var(--primary-color);
        }
        &.active{
          background-color: var(--primary-color);
          svg{
            color: #fff;
          }
        }
        &:first-child{
          border-radius: $borderRadius 0 0 $borderRadius;
        }
        &:last-child{
          border-radius: 0 $borderRadius $borderRadius 0;
        }
      }
    }
    .krg-dates{
      display: flex;
      min-width: 300px;
      margin-bottom: 0;
    }
  }
}

.kpi--circle {
  margin: 10px 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

@media (max-width: 440px) {
  .kpi-table-left{
    max-width: 135px;
    .kpi-table-cell{
      max-width: 135px;
    }
  }
}
