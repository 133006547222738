@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.left-menu {
  position: relative;
  width: 220px;
  transition: width 0.3s, transform 0.3s;
  box-shadow: $boxShadowRight;
  padding: 80px 0 10px;
  background-color: var(--primary-color);

  &.impersonate {
    padding-top: 120px;
  }
  .left-menu-sections {
    max-height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .left-menu-nav-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    p {
      font-weight: 600;
      font-size: 16px;
      white-space: nowrap;
      margin-bottom: 0;
    }
    p, svg { color: #fff }
    
    & > li {
      margin-top: 5px;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .nav-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-radius: $borderRadiusLight;
        width: 100% !important;
        height: 52px !important;
        gap: 10px;

        &:hover {
          background-color: hsla(0, 0%, 100%, 0.1);
        }

        & > .nav-item-submenu-icon {
          svg {
            transform: rotate(-90deg);
            transition: transform 0.3s ease;
          }
          &.rotate svg { 
            transform: rotate(0deg);
          }
        }
      }
      
      &.active .nav-item {
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      ul.left-menu-nav-subitems {
        display: none;
        flex-direction: column;
        padding-left: 10px;
        width: 100%;

        li {
          margin-top: 2px;
          padding: 5px 15px;
          border-radius: $borderRadiusLight;
          p {
            font-weight: 400;
          }
          &.active, &:hover {
            background-color: hsla(0, 0%, 100%, 0.1);
          }
          &.hide {
            display: none;
          }
        }

        &.show {
          display: flex;
        }
      }
    }
    
    & > li.active { 
      ul.left-menu-nav-subitems {
        display: flex;
      }
      .nav-item-submenu-icon svg {
        transform: rotate(0deg);
      }
    }

    .investments-select,
    .startups-select {
      display: none;
      margin-bottom: 5px;
      .input-select-wrapper {
        margin: 0;
      }
      .shareholders-select {
        margin-bottom: 15px;
      }
    }
  }

  &:not(.show) {
    &:not(:hover) {
      width: 86px;
      .nav-item p,
      .nav-item-submenu-icon,
      .left-menu-nav-subitems {
        display: none !important;
      }
    }
  }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  body:not(.touchscreen) {
    .left-menu {
      &:not(:hover) {
        width: 0;
        overflow-x: hidden;
        
        &.show {
          width: 86px;
        }
        
        .nav-item p,
        .nav-item-submenu-icon,
        .left-menu-nav-subitems {
          display: none !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .left-menu {
    transform: translateX(-50vw);
    width: 50vw !important;

    &.show {
      transform: translateX(0vw);
    }

    .left-menu-nav-items .startups-select,
    .left-menu-nav-items .investments-select {
      display: block;
      .input-select-select {
        background-color: white;
        overflow: hidden;
        svg { color: #000 }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .left-menu {
    transform: translateX(-86vw);
    width: 86vw  !important;
  }
}
