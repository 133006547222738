@import 'src/styles/_colors';
@import 'src/styles/_variables';
@import 'src/styles/breakpoints';

.tool{
  .tool-tabs-selector {
    display: none;
    .input-select-select {
      background-color: var(--primary-color);
      span, svg { 
        font-size: 16px;
        color: #fff !important;
      }
      .selected-item{
        padding-left: 25px;
        text-transform: uppercase;
      }
    }
  }
  .tool-tabs{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: $spacing;
    &.justify-start{
      justify-content: flex-start;
    }
    &.justify-end{
      justify-content: flex-end;
    }
    .tool-tab{
      padding: 0 25px 5px;
      border-bottom: 2px solid transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      color: #838383;
      cursor: pointer;
      &:hover,
      &.active{
        color: var(--primary-color);
        border-bottom-color: var(--primary-color);
      }
      &:not(:last-child){
        margin-right: 10px;
      }
    }

    &.style-panel{
      box-shadow: $boxShadowLight;
      border-radius: $borderRadiusLight;
      display: flex;
      align-items: center;
      width: fit-content;
      .tool-tab{
        padding: 15px 25px;
        cursor: pointer;
        line-height: 1;
        font-size: 14px;
        text-transform: uppercase;
        white-space: nowrap;
        background-color: #fff;
        margin: 0 !important;
        &:first-child{
          border-radius: $borderRadiusLight 0 0 $borderRadiusLight;
        }
        &:not(:last-child){
          border-right: 1px solid $borderGrey;
        }
        &:last-child{
          border-radius: 0 $borderRadiusLight $borderRadiusLight 0;
        }
        &:only-child{
          border-radius: $borderRadiusLight;
        }
        &:hover,
        &.active{
          background-color: var(--primary-color);
          color: white;
        }
      }
    }
  }
}

@media(max-width: $breakpoint-sm) {
  .tool{
    .tool-tabs{
      display: none !important;
    }
    .tool-tabs-selector{
      display: block;
    }
  }
}