.operation-actions-stats{
  display: flex;
  flex-wrap: wrap;
  width: 240px;
  
  li{
    background-color: transparent;
    opacity: 0.6;
    margin-right: 5px;
    padding: 3px;
    font-size: 14px;
    width: 30px;
    line-height: 1;
    text-align: center;
    &.active{
      background-color: var(--primary-color-25);
      opacity: 1;
    }
  }
}

.operation-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
  z-index: 1;
  .timeline {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    &.confirmed {
      background-color: var(--primary-color);
      svg { 
        color: white; 
        width: 11px;
      }
    }
  }
}

.table-row {
  &:not(:first-child) .timeline::before {
    position: absolute;
    top: 0;
    height: 50%;
    width: 1px;
    background-color: var(--primary-color);
    content: '';
    z-index: -1 !important;
  }
  &:not(:last-child) .timeline::after {
    position: absolute;
    bottom: -1px;
    height: 50%;
    width: 1px;
    background-color: var(--primary-color);
    content: '';
    z-index: -1 !important;
  }
}
.timeline-container {
  position: relative;
  left: 50px;
  margin: 20px auto;
  width: 70%;
  border-left: 1px solid var(--primary-color);
  .timeline-row{
    position: relative;
    padding: 10px;
    .date{
      position: absolute;
      top: 22px;
      left: -80px;
      font-weight: bold;
      &.year {
        top: -17px;
        left: -90px;
        font-size: 25px;
      }
    }
    .operation {
      cursor: pointer;
      margin-left: 20px;
      .operation-name {
        span{
          font-weight: bold;
        }
      }
      .operation-info{
        height: 4px;
        overflow-y: hidden;
        &.operation-info-open {
          height: auto
        }
        .highlight{
          color: var(--primary-color);
        }
        .details{
          li{
            position: relative;
            padding: 0 0 5px 30px;
            &::before{
              content: '-';
              position: absolute;
              left: 8px;
              top: px
            }
          }
        }
      }
      &::before{
        position: absolute;
        top: 14px;
        left: -26px;
        content: "";
        border-radius: 50%;
        width: 11px;
        height: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 20px;
        padding: 3px;
        background-color: var(--primary-color);
      }
      &.year::before{
        top: -20px;
        left: -29px;
        border: 5px solid var(--primary-color);
        padding: 3px;
        background-color: #fff;
      }
    }
  }
}

.waterfalls-table{
  .cell-content {
      p {
          margin: 0;
      }
  }
}