.startup-searcher {
    .input-select-text {
        margin: 0 !important;
    }
    .selected-item {
        min-height: 55px;
    }
    .startup-selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        width: 55px;
    }
}