@import 'src/styles/_colors';
@import 'src/styles/_variables';

.ccollapse{
  border-radius: $borderRadius;
  margin: 0 0 $marginListBetween 0;

  .ccollapse-header{
    border-radius: $borderRadius;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: $boxPadding;
    background-color: var(--primary-color-15);
    cursor: pointer;
    h5{
      flex: 1;
      margin: 0;
      svg{
        vertical-align: middle;
        &.pre-icon{
          margin-right: 5px;
        }
        &.post-icon{
          margin-left: 5px;
        }
      }
    }
    .arrow-right {
      margin-left: 15px;
      transition-duration: 0.5s;
      transition-property: transform;
      color: var(--primary-color);
      transform: rotate(0deg);
    }
  }
  .ccollapse-body{
      padding: $boxPadding;
      display: none;
  }
  
  &.open{
    box-shadow: $boxShadowMedium;
    .ccollapse-header{
      background-color: var(--primary-color-25);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      h5{
        font-weight: bold;
      }
      .arrow-right {
        transform: rotate(180deg);
      }
    }
    .ccollapse-body{
      display: block;
    }
  }
}