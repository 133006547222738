@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.operation-wrapper{
  .btn-operation{
    ul{
      display: none;
      &.show{
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      li{
        margin-left: 15px;
      }
    }
  }
  .operation-total-errors{
    background-color: #FFE3E7;
    padding: $boxPadding;
    svg{
      margin-right: 10px;
      color: $red;
      width: 18px;
      height: auto;
    }
  }
  .operation-list{
    & > li{
      display: flex;
      cursor: pointer;
      padding: 25px;
      &.contain-errors{
        border-left: 5px solid $red;
        &:first-child{
          border-radius: $borderRadius $borderRadius 0 0;
        }
        &:last-child{
          border-radius: 0 0 $borderRadius $borderRadius;
        }
        &:only-child{
          border-radius: $borderRadius $borderRadius;
        }
      }
      .operation-info {
        flex-grow: 1;
        width: 100;
        h5{
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg{
            margin-right: 10px;
            color: $red;
            width: 18px;
            height: auto;
          }
        }
        .checkIcon{
          svg{
            color: black;
          }
        }
        .operation-detail,
        .operation-errors{
          padding-left: 10px;
        }
        .operation-detail{
          margin-top: 15px;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          & > div{
            padding: 0 35px 10px 0;
            h6{
              margin: 0;
            }
            span{
              margin-top: 5px;
              color: var(--primary-color);
            }
          }
        }
        .operation-errors{
          margin-top: 5px;
          span{
            color: $darkRed;
            font-weight: 600;
          }
        }
      }
      .row-icon{
        display: none;
      }
      &:hover{
        .row-icon{
          display: block;
        }
      }
      &:not(:last-child){
        border-bottom: 1px solid var(--primary-color-15);
      }
    }
  }
  .operation-errors{
    max-height: 250px;
    overflow: auto;
  }
}

.shares-assigments{
  .shares-assigments-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    .sh-automatic{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      input{
        margin-right: 5px;
      }
    }
    .sh-prorata {
      span {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        };
      }
    }
  }
  .shares-assigments-table{
    overflow: auto;
    border: 1px solid $borderLightGrey;
    .sh-header{
      background-color: $lightGrey;
      color: $text-color;
      span{
        font-weight: bold;
        padding: 8px 10px;
        width: 100%;
        display: block;
        line-height: 1.7;
      }
      border-radius: $borderRadiusExtraLight;
    }
    .sh-header,
    ul li:not(.add-shareholder){
      display: flex;
      height: 40px;
      & > *{
        flex: 0 19%;
        min-width: 19%;
        &.cell-vp {
          flex: 0 38%;
        }
        &.cell-sh{
          flex: 1 0px;
        }
        &.delete-row,
        &.move-row{
          width: 18px;
          min-width: 18px;
        }
      }
      &.dividend {
        & > *{
          flex: 0 25%;
          min-width: 25%;

          &.cell-sh{
            flex: 1 0px;
          }
          * {
            height: 100%;
          }
          .output_value {
            display: flex;
            align-items: center;
          }
        }
      }      
      &.reduce-row {
        & > div:not(.cell-sh) {
          flex: 0 16%;
          min-width: 150px;
          span {
            padding-left: 5px;
          }
        }
        &.cell-sh{
          min-width: 150px;
          flex: auto;
        }
      }
    }
    ul{
      li{
        position: relative;

        & > *:not(:last-child) {
          border-right: 1px solid var(--primary-color-25);
        }
        h6,
        .pre_text{
          display: none;
        }
        .input-select-select,
        .input,
        .output_value{
          border-radius: 0;
          height: 40px;
          border-right: none
        }
        .move-row,
        .delete-row{
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        .move-row{
          left: 0px;
          margin-left: 5px;
          height: 100%;
          cursor: grab;
        }
        .delete-row{
          right: 2px;
          cursor: pointer;
        }
      }
    }
    .add-shareholder{
      border-top: 1px solid $borderGrey;
      display: flex;
      align-items: center;
      padding: 10px 0;
      flex: 1 0px;
      justify-content: center;
      cursor: pointer;
      svg{
        margin-right: 5px;
      }
    }
  }
  &.editable{
    .shares-assigments-table{
      overflow-x: auto;
      overflow-y: visible;
      .sat-overflow{
        min-width: 650px;
      }
      .sh-header{
        padding: 0 25px;
      }
      ul{
        li{
          padding: 0 25px;
          * {
            border-bottom: none !important;
            border-left: none !important;
          }
          & > *:not(:first-child):not(:last-child) {
            border-right: 1px solid var(--primary-color-25);
          }
        }
      }
    }
  }
  &:not(.editable){
    .shares-assigments-table{
      ul{
        li:last-child{
          border-radius: 0 0 $borderRadiusMedium $borderRadiusMedium;
          overflow: hidden;
        }
      }
    }
  }
}
.suboperations-list{
  .box {
    margin-bottom: $marginListBetween;
    &:not(.disabled){
      cursor: pointer;
      &:hover{
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color-15) !important;
      }
    }
    &.disabled{
      cursor: not-allowed;
      * {
        opacity: 0.6;
      }
    }
    span{
      display: block;
    }
  }
}

.operation-form-assignOptions {
  .options-list {
    margin: 0px 0px 5px 0px;
    padding-bottom: 5px;
    &:not(:last-child) {
      border-bottom: 1px solid $borderLightGrey;
    }
  }
}

.operation-list-valuation{
  & > li{
    display: flex;
    cursor: pointer;
    padding: 25px;
    .operation-info {
      h5{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg{
          margin-right: 10px;
          width: 18px;
          height: auto;
        }
      }
      .operation-detail{
        margin-top: 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        & > div{
          padding: 0 35px 10px 0;
          h6{
            margin: 0;
          }
          span{
            margin-top: 5px;
            color: var(--primary-color);
          }
        }
      }
    }
    &:not(:last-child){
      border-bottom: 1px solid var(--primary-color-15);
    }
  }
}

.reference-operation {
  display: flex;
  position: relative;
  .reference-operation-info {
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 0;
    @include box;
    padding: $boxPadding;
    z-index: 2;
    transition: opacity 0.2s ease-in-out;
  }
  .reference-operation-name {
    display: flex;
    align-items: center;
    cursor: help;
    &:hover + .reference-operation-info {
      opacity: 1;
    }
  }
}

@media (max-width: $breakpoint-sm){
  .operation-wrapper{
    .btn-operation{
      ul{
        li{
          margin: 0;
          width: 50%;
          padding: 5px;
          button{
            width: 100%;
          }
        }
      }
    }
    
    .operation-list{
      & > li{
        .operation-info {
          .operation-detail{
            display: flex;
            & > *{
              width: 50%;
            }
          }        
        }        
      }
    }
  }

  .shares-assigments{
    .shares-assigments-header {
      flex-direction: column-reverse;
      gap: 10px;
      align-items: center ;
    }

    .add-shareholder{
      justify-content: flex-start;
      padding-left: 35px;
    }
  }
}