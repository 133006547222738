@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.form-wrapper-notFound{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  flex-direction: column;
}