@import 'src/styles/_colors';
@import 'src/styles/_variables';

.toolAdminPlatform{
  .tabEmails{
    .emailMergetags{
      padding: 15px;
      background-color: #f7f7f7;
      margin-bottom: 25px;
      &.columnTags{
        height: 90%;
        margin-top: 25px;
        padding-top: 25px;
      }
    }
  }

  .tabForms{
    .tableButton{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-end;
      & > div{
        display: inline-block;
      }
    }
    .forms{
      position: relative;
      width: 100%;
      margin-top: 40px;
    }
    .rowTable{
      width: 100%;
    }
    .rowForm{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $marginListBetween;
      &_order{
        margin: 0;
        padding-right: 10px;
      }
      &_content{
        width: 100%;
        background-color:var(--primary-light-color);
        padding: $boxPadding;
        border-radius: 10px;
        &-title{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          p{
            padding-left: 5px;
            margin: 0;
          }
        }
        &-options{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & > div:not(:first-child){
            margin-left: 15px;
          }
          span{
            margin-left: 5px;
            display: inline-block;
          }
        }
      }
      &_icons{
        padding-left: 10px;
        svg{
          cursor: pointer;
        }
      }
    }
    .rowForm_grip-icon{
      margin-right: 5px;
      width: auto;
      height: 25px;
    }
    
    .trash{
      margin: 4px 0px;
      width: 18px;
    }
    .arrow-hide{
      padding-bottom: 100%;
    }
    .addButton{
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
  }

  .tabStartupOptions {
    .gear-svg {
      stroke: var(--primary-color);
      width: 18px;
    }
  }
}

.answersList{
  margin-bottom: $marginListBetween;
  li{
    margin-bottom: $marginListBetween;
    .questions_answer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input_text{
        margin: 0 !important;
        .post_text,
        .pre_text{
          display: none;
        }
      }
      .questions_answer-order{
        padding-right: 10px;
        margin: 0;
      }
      .questions_answer-grip{
        margin-right: 5px;
        width: auto;
        height: 20px;
      }
      .questions_answer-icons{
        padding-left: 10px;
        margin: 0;
        & > div{
          cursor: pointer;
        }
      }
    }
  }
}