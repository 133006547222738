@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.topbar {
  width: 100%;
  height: 80px;
  background-color: $white;
  box-shadow: $boxShadow;
  margin-left: auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  .topbar-left-section {
    display: flex;
    align-items: center;
    height: 100%;
    .mobile-button{
      width: 35px;
      height: auto;
      margin-right: 10px;
      padding: 3px;
      cursor: pointer;
      &:hover{
        background-color: var(--primary-light-color);
        border-radius: 50%;
      }
    }
    a{
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .topbar-logo {
        margin-right: 50px;
        width: auto;
        height: auto;
        max-width: 120px;
        max-height: 100%;
      }
    }
    .input-select-wrapper{
      width: 300px;
      margin-bottom: 0;
      .input-select-select{
        height: 50px;
        padding: 15px 18px;
      }
    }
  }
  .topbar-right-section {
    display: flex;
    align-items: center;
    .topbar-action {
      position: relative;
      margin-left: 30px;
      .topbar-icon {
        background-color: $white;
        border-radius: 50%;
        height: 46px;
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        cursor: pointer;
        padding: 10px;
        .topbar-icon-number{
          position: absolute;
          bottom: -6px;
          right: -6px;
          font-size: 14px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &:hover,
      &.active {
        .topbar-icon {
          background-color: var(--primary-light-color);
          svg{
            width: 22px;
            height: auto;
          }
          .topbar-icon-number{
            background-color: var(--primary-light-color);
          }
        }
      }
      &.active{
        .topbar-icon {
          position: relative;
          z-index: 5;
        }
      }
    }
    .default-profile-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 40px;
      cursor: pointer;
      z-index: 5;
    }
  }
  .investments-select {
    display: flex;
    gap: 20px;
    .currency-select {
      width: 200px;
    }
  }
}

.toggle_option{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 10px 10px 0 0;
  svg{
    margin-left: 10px;
    color: $mediumGray;
  }
  &.active{
    svg{
      color: #43A047;
    }
  }
}

.toast-message {
  border-radius: $borderRadius !important;
} 

.custom-progress-bar {
  background: var(--primary-color) !important;
}

.topbar-popup{
  width: 250px;
  box-shadow: $boxShadow;
  padding: 15px;
  background-color: $white;
  border-radius: $borderRadius;

  &.profile-options-wrapper {
    .option-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 10px;
      color: $text-color;
      cursor: pointer;
      .option-icon {
        margin-right: 12px;
        color: $icon-color;
      }
    }
    .option-item:hover {
      background-color: #ab9ce625;
      color: var(--primary-color);
      .option-icon {
        color: var(--primary-color);
      }
    }
  }

  &.topbar-notifications{
    width: 350px;
    max-width: calc(100vw - 50px);
    .notifications-filter{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 5px;
      padding-right: 5px;
      span{
        padding: 5px 10px;
        border-radius: $borderRadius;
        margin-left: 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover,
        &.active{
          background-color: var(--primary-light-color);
        }
        &.active{
          font-weight: bold;
        }
      }
    }
    .notifications-list{
      max-height: 60vh;
      overflow: auto;
      position: relative;
      .notification{
        padding: 10px 0px 10px 10px ;
        display: flex;
        justify-content: space-between;
        .notification-content {
          position: relative;
          width: calc(100%);
          &:not(.read)::after{
            position: absolute;
            right: -17px;
            bottom: 5px;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--primary-color);
            margin-top: auto;
            margin-bottom: auto;
          }
          &.read{
            p,
            .notification-date{
              opacity: 0.75;
            }
          }
          .notification-date{
            display: block;
            color: var(--primary-color);
          }
          p{
            color: $textColor;
            margin: 0 0 5px 0;
          }
        }
        .notification-actions{
          opacity: 0;
        }
        &:hover{
          background-color: $backgroundHover;
          .notification-actions{
            opacity: 1;
          }
        }
        &:not(:last-child){
          border-bottom: 1px solid $greyLight;
          padding-bottom: 15px;
        }
      }
      .panel-empty-notifications{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        img{
          max-height: 150px;
          max-width: calc(100% - 50px);
        }
      }
    }
  }
  
}



@media (max-width: $breakpoint-md) {
  .topbar {
    .topbar-left-section {
      .mobile-button{
        display: block;
      }
      .input-select-wrapper,
      .investments-select{
        display: none;
      }
    }
    .topbar-right-section{
      .topbar-action,
      .default-profile-image{
        margin-left: 30px;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .topbar {
    .topbar-right-section{
      .topbar-action,
      .default-profile-image{
        margin-left: 20px;        
      }
      .viewMyPosition-button {
        display: none;
      }
    }
  }
}