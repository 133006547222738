/***************************Color Variables****************************/

$primary: #9074b4;
$secondary: #f50057;
$text-color: #000000DE;
$icon-color: #00000099;
$textColor: #000000DE;
$textColorLight: #838383;
$text-color-faded: rgba(104, 104, 104, 0.6);
$borderGrey: #E2E8F0;
$white: #fff;
$black: #373737;
$gray: #e0e0e0;
$greyLight: #c9c9c9;
$mediumGray: #979797;
$darkGray: #646464;
$bodyColor: #fbfcff;
$placeholder: rgba(55, 58, 60, 0.5);
$countColor: #a6a7a8;
$borderBlue: #a1adcd;
$darkBlue: #183270;
$lightBorder: rgba(209, 206, 245, 0.6);
$lightGreen: #72bc08;
$lightGrey: #F8FAFC;
$darkRed: #bf0808;
$roundBorder: #979797;
$breadcrumbColor: #055cc6;
$notification-color: #eb3232;
$borderLightGrey: #e2e6f2;
$borderDarkGrey: #c9c9c9;
$paragraphColor: #a4a4a4;
$darkText: #4a4a4a;
$lined-filled-btn: #bdc7de;
$formBorder: #cccccc;
$BGoverlay: rgba(169, 169, 169, 0.7);
$redish: #d35e5e;
$BGoverlay: rgba(169, 169, 169, 0.7);
$svgColor: #979797;

$orange: #fe8622;
$darkOrange: #4d4a49;
$flatOrange: #fc4236;
$red: #ff2400;
$purple: #b71b46;
$green: #2ecc71;
$colorGraphUp: #88E927;
$colorGraphDown: #E70E31;

/***************************Fonts Variables****************************/

$light: 'AktivGrotesk-light', serif;
$regular: 'AktivGrotesk-regular', serif;
$medium: 'AktivGrotesk-medium', serif;
$bold: 'AktivGrotesk-bold', serif;

:root {
  --primary-color: #{$primary};
  --secondary-color: #07D9CA;
  --primary-light-color: #{$primary}15;
  --primary-color-15: #{$primary}15;
  --primary-color-25: #{$primary}25;
  --primary-color-50: #{$primary}50;
  --primary-color-75: #{$primary}75;
  --success-color: #{$green};
}
