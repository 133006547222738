@import "src/styles/_colors";

.pemission-label {
    position: relative;
    display: inline-flex;
    margin-left: 5px;
    background-color: $darkGray;
    border-radius: 50%;
    color: white;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    padding: 3px;
    svg {
        width: 100%;
    }
}

.permissions-popup {
    .permissions-empty{
        text-align: center;
        color: $textColorLight;
        font-style: italic;
        margin: 10px 0;
    }
    .permissions-rows {
        max-height: 350px;
        overflow: auto;
        .permissions-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .permissions-row-name{
                display: flex;
                align-items: center;
                gap: 10px;
            }
            .input-select-select {
                min-width: 150px;
            }
            .permissions-row--left {
                display: flex;
                flex: 1;
                align-items: center;
            }
            svg {
                color: $svgColor;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }

    .permissions-searcher{
        .pre_text {
            display: inline-block;
            margin-bottom: 5px;
            color: $textColorLight;
            font-size: 14px;
        }
        .inputs {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            & > *:first-child {
                flex-grow: 1;
            }
            & > *:last-child {
                width: 125px;
            }
        }
    }
}