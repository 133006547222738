@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.waterfall-comparator-info {
  margin: 0px;
  &:not(:first-child) {
    margin-top: 15px;
  }
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid $borderLightGrey;
  }
  .waterfall-name {
    display: flex;
    align-items: center;
    .waterfall-name--circle {
      margin-right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .waterfall-name--name {
      font-weight: bold;
      cursor: pointer;
    }
  }
  .waterfall-description {
    p{
      margin: 0
    };
  }
}

.waterfall-comparator-table {
  .input-select-table {
    margin-left: auto;
    z-index: 0;
    width: 30%;
  }

  .table-component {
    position: relative;
    top: -50px;
    .table-cell:not(.fluid) {
      padding: 0 !important;
    }
    .table-header-cell:not(.fluid) {
      justify-content: flex-start !important;
      padding-left: 0;
      padding-right: 0;
    }
    .cell-content {
      display: block !important;
    }
  }
  .waterfall-table-full .table-component{
    .cell-value {
      height: 32px;
      padding: 5px 0px;
      &:not(:last-child) {
        border-bottom: 1px solid $borderLightGrey;
      }
    }
    .cell-circle {
      padding-top: 2px;
      height: 32px;
      display: flex;
      align-items: center;
      width: 40px;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $borderLightGrey;
      }
    }
  }
  
  .waterfall-table-summary .table-component{
    .cell {
      padding: 10px;
    }
    .cell-green {
      background-color: #{$colorGraphUp}50;
    }
    .cell-red {
      background-color: #{$colorGraphDown}50;
    }
  }
  .waterfall-table.loading {
    .cell-value {
      padding: 0px;
    }
    .cell-content {
      padding: 0px 10px !important;
    }
  }
}

@media (max-width: 1245px){
  .waterfall-comparator-table {
    .input-select-table {
      width: 100%;
    }
    .table-component {
      top: 0;
    }
  }
}