@import 'src/styles/_colors';

.popUp-info{
    .popUp-info-header{
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #B5B5B5;
        padding-bottom: 15px;
        margin-bottom: 25px;
    }
    .popUp-info-header-avatar{
        padding-right: 10px;
    }
}