$boxPadding: 15px;
$boxShadow: 0px 3px 9px #0000001C;
$boxShadowRight: 3px 6px 9px 0px #0000001C;
$boxShadowMedium: 0px 3px 6px #00000029;
$boxShadowLight: 0px 3px 3px #00000029;
$borderRadius: 12px;
$borderRadiusMedium: 8px;
$borderRadiusLight: 5px;
$borderRadiusExtraLight: 3px;
$spacing: 25px;
$spacingSM: 15px;
$marginListBetween: 15px;
$marginCols: 30px;
$backgroundHover: #11B3C21A;

$toolViewHeight: calc(100vh - 115px);
$stickyTop: 115px;
