@import "src/styles/breakpoints";

.sp-columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .performance-graph {
    width: 50%;
    max-width: 500px;
    .echarts-for-react {
      margin: 0 10px 0 0 !important;
    }
  }
  .performance-table {
    flex: 1;
  }
}

@media (max-width: $breakpoint-md) {
  .sp-columns {
    flex-direction: column;
    .performance-graph {
        max-width: 100% !important;
        width: 100% !important;
        flex: 1;
      }
  }
}
