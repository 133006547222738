@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.myInvestments-wrapper {
  .myInvestments-header {
    display: flex;
    justify-content: flex-end;
    gap: 50px;
    margin-bottom: 30px;
    .shareholders, .currencies {
      display: flex;
      align-items: flex-end;
      gap: 15px;
      .input-select-wrapper {
        width: 250px;
        margin-bottom: 0px;
      }
      svg.action-icon {
        font-size: 35px;
        padding: 5px;
        margin-bottom: 7px;
        &:hover {
          cursor: pointer;
          border-radius: 40%;
          background-color: $gray;
        }
      }
    }
  }

  .currencies-popup {
    .input_number {
      .pre_text {
        display: none;
      }
    }
  }
  .no-results {
    text-align: center;
    padding: 35px 0;
    img{
      margin-bottom: 15px;
      width: 250px;
      max-width: calc(100% - 50px);
    }
  }

  .toggle-table {
    border-radius: $borderRadiusLight;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $boxShadowLight;
    padding: 10px 20px;
    width: 140px;
    margin-bottom: 20px;
    .input_toggle {
      margin: 0;
      justify-content: center;
    }
    svg {
      font-size: 22px;
    }
  }
}

@media (max-width: 767px) {
  .myInvestments-wrapper {
    .myInvestments-header {
      flex-direction: column;
      gap: 0;
      .shareholders, .currencies {
        margin-bottom: 20px;
        .input-select-wrapper {
          flex: 1;
        }
      }
    }
  }
}



