@import 'src/styles/_colors';
@import 'src/styles/_variables';


.popUpVestingPlan{      
    .time-box,
    .milestone-box{
        margin-top: $boxPadding;
        border-radius: $borderRadius;
        div{
            .vestingPlan-separator{
                margin-top: 0;
            }
        }
        .row-elem{
             .vestingPlan-trash{
                justify-content: center;
                align-items: flex-end;
                margin-bottom: 12px;
            }
        }
    }
        
    .vestingPlan-trash{
        svg {
            cursor: pointer;
        }
        display: flex;
        align-items: center;
    }
        
    .vestingPlan-time,
    .vestingPlan-milestone{
        margin-bottom: $spacingSM;
        padding: $boxPadding;
        background-color: var(--primary-color-15);
        border-radius: $borderRadius;
    }
    .buttons{
        padding: $boxPadding;
    }
    .vestingPlan-percentage{
        margin: $boxPadding;
        border-radius: $borderRadius;
    }
}