@import 'src/styles/colors';

.legal-document{
  & > *{
    margin-bottom: 15px;
  }  
  h1{
    font-size: 24px;
  }
  h2{
    font-size: 20px;
  }
  h3{
    font-size: 18px;
  }
  p{
    font-size: 16px;
    display: block;
    text-align: justify;
  }
  ul,
  ol{
    padding-left: 25px;
    li{
      list-style: disc;
    }
  }
}

.go-home-button {
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  img {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  &:hover{
    font-family: $bold;
  }
}