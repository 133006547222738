@import 'src/styles/colors';

.tool-disabled {
  height: 50vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  h2 {
    font-size:1.4rem;
    font-weight: 300;
    line-height: inherit;
    margin:0;
    padding:0
  }
}
