.progress-bar-component {
  position: relative;
  width: 100%;
  height: 25px;
  border-radius: 50px;
  background-color: #efefef;
  text-align: center;
  .progress {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
    border-radius: 50px;
    background-color: var(--primary-color);
    transition: width 0.6s;
  }
  .percentage{
    color: white;
    font-weight: bold;
    font-size: 14px;
    &.zero {
      color: inherit;
    }
  }
}