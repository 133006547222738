@import 'src/styles/colors.scss';

footer.legal-footer {
  padding: 15px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  background-color: $white;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 6;
  box-shadow: 0px 2px 15px #0000000e;
  a {
    font-size: 12px;
    padding: 0 7px;
    color: $text-color;
    display: inline-block;
    &:not(:last-child) {
      border-right: 1px solid $text-color;
    }
  }
}
