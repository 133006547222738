@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/breakpoints";

.topbar-embed {
  justify-content: center;
  .embed-topbar-section {
    display: flex;
    align-items: center;
    padding: 0 50px;
    max-width: 1280px;
    width: 100%;
    gap: 20px;
    .menu-icon {
      display: none;
      width: 35px;
      height: auto;
      margin-right: 10px;
      padding: 3px;
      cursor: pointer;
      &:hover{
        background-color: var(--primary-light-color);
        border-radius: 50%;
      }
    }
  }
}
.submenu-popup {
  display: none;
}

.embed-nav-item {
  position: relative;
  color: $text-color;
  font-size: 16px;
  &.active,
  &:hover {
    text-shadow: 0 0 0.1px black;
    text-decoration: underline !important;
    text-decoration-thickness: 2px;
    text-decoration-color: var(--primary-color);
    text-underline-offset: 10px;
  }
  &.hide {
    display: none;
  }
}

@media (max-width: $breakpoint-md) {
  .topbar-embed {
    justify-content: flex-end;
    .embed-topbar-section {
      padding: 0;
      width: 45px;
      .menu-icon {
        display: block;
      }
      .embed-nav-item {
        display: none;
      }
      
    }
  }
  .submenu-popup {
    display: flex;
    flex-direction: column;
    width: 250px;
    box-shadow: $boxShadow;
    padding: 15px;
    background-color: $white;
    border-radius: $borderRadius;
    .embed-nav-item {
      color: $text-color;
      padding: 5px;
      text-decoration: none !important;
    }
  }
}
