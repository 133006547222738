@import 'src/styles/breakpoints';
@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.mentorings-wrapper{
  .mentoring-row{
    display: flex;
    margin-bottom: $marginListBetween;
    padding: $boxPadding;
    color: $text-color;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    box-shadow: $boxShadowMedium;
    & > *{
      padding: 0 15px;
    }
    .mentoring-info{
      flex: 1 0px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .mentoring-avatar{
        padding-right: 30px;
      }
      .mentoring-basic{
        flex: 1 0px;
        padding-right: 20px;
        .mentoring-avatar-mobile{
          display: none;
        }
        .mentoring-message{
          border: 1px solid $borderGrey;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: $borderRadius;
          width: 100%;
          max-height: 150px;
          overflow-y: auto;
          .message{
            font-style: italic;
            padding-left: 10px;
          }
        }
      }

      .mentoring-special{
        display: flex;
        text-align: right;
        display: flex;
        align-items: center;
        span{
          margin-right: 5px;
          max-width: 150px;
          @include textEllipsis;
        }
      }
    }

    .mentoring-actions{
      min-width: 270px;
      max-width: 345px;
      align-items: flex-start;
      justify-content: center;
      border-left: 1px solid $greyLight;
      display: flex;
      flex-direction: column;
      .mentoring-recommended{
        background-color: #F8641C;
        padding: 5px 10px;
        border-radius: 15px;
        color: #fff;
        font-size: 14px;
        svg{
          color: #fff;
          font-size: 14px;
        }
      }
      svg{
        color: var(--primary-color);
        margin-right: 5px;
      }
      & > div{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:not(:last-child){
          margin-bottom: 10px;
        }
        span{
          b{
            padding-left: 5px;
          }
        }
        &.mentorings-actions-buttons{
          width: 100%;
          justify-content: center;
          button:not(:last-child){
            margin-right: 5px;
          }
          button{
            flex: 1 0px;
          }
        }
      }
    }
    .mentoring-invite{
      padding-top: $spacing;
      width: 100%;
    }
  }
  .mentorings-empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0;
    position: relative;
    img{
      max-width: 75%;
      height: auto;
      max-height: 300px;
      margin-bottom: 25px;
      position: relative;
      z-index: 2;
    }
    img.arrow-search{
      position: absolute;
      top: -50px;
      right: 100px;
      z-index: 1;
    }
  }
}

@media (max-width: $breakpoint-lg){
  .mentorings-wrapper{
    .mentoring-row{
      & > div{
        width: 100%;
        flex: 1 0px !important;
      }
      .mentoring-info{
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        & > div{
          width: 100%;
          flex: 1 0px !important;
        }
        .mentoring-basic{
          h4{
            display: flex;
            align-items: center;
            .mentoring-avatar-mobile{
              display: inline-flex;
              margin-right: 10px;
            }
          }
        }
        .mentoring-special{
          padding-left: 0px;
        }
        .mentoring-avatar{
          display: none;
        }
      }
      
    }
  }
}

@media (max-width: 650px){
  .mentorings-wrapper{
    .mentoring-row{
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      & > *{
        padding: 0 0 15px 0;
        width: 100%;
        &:last-child{
          padding-bottom: 0;
        }
      }
      .mentoring-info{
        .mentoring-basic{
          h4{
            display: flex;
            align-items: center;
            .mentoring-avatar-mobile{
              display: inline-flex;
              margin-right: 10px;
            }
          }
        }
        .mentoring-avatar{
          display: none;
        }
      }
      .mentoring-actions{
        border-left: none !important;
        border-top: 1px solid $greyLight;
        padding-top: 15px;
        max-width: 600px;
        .mentorings-actions-buttons{
          flex-direction: column;
          button{
            width: 100%;
          }
          & > *:not(:last-child){
            margin-bottom: $marginListBetween;
          }
        }
      }
    }
    .mentorings-empty{
      img.arrow-search{
        display: none;
      }
    }
  }
}