@import 'src/styles/colors';
.profile-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  .profile-card-logo {
    width: 123px;
    height: 123px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .profile-card-avatar {
    margin-bottom: 20px;
  }
  .profile-card-title {
    text-align: center;
  }
  .profile-card-body{
    width: 100%;
  }
  .profile-card-description {
    text-align: center;
  }
  .profile-card-details {
    background-color: var(--primary-light-color);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    .profile-card-details-description{
      word-break: break-all;
      * {
        word-break: break-all;
      }
    }
  }
  .buttons{
    margin-top: 20px;
  }
}
