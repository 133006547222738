@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';


.setup-captable {
    display: flex;
    align-items: center;
    gap: 100px;
    .setup-captable--message {
        flex: 1;
        img {
            display: flex;
            margin: auto;
            height: 200px;
            width: 260px;
        }
    }
    .setup-captable--actions {
        flex: 1.5;
        li {
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            svg {
                font-size: 20px;
            }
            &:not(:last-child) {
                border-bottom: 1px solid $borderLightGrey;
            }
            &:hover {
                span,
                svg {
                    color: var(--primary-color);
                    font-weight: 600;
                }
            }
            .keyword {
                background-color: #E0E0E0;
                padding: 5px 10px;
                font-size: 12px;
                margin-left: 10px;
                font-weight: bold;
                border-radius: 12px;
            }
        }
    }
}

.captable-features {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.captable-import {
    ol {
        list-style: decimal;
        padding-left: 40px;
        li {
            line-height: 2;
        }
    }
}

.panel-summary-shareClass,
.panel-summary-shareholder,
.panel-summary-options {
    height: 315px;
    .table-row {
        height: 42px;
    }
    .no-results {
        max-height: 215px;
        overflow: hidden;
        position: fixed;
        height: 200px;
        img {
            width: 200px !important;
        }
    }
    * {
        overflow: visible;
    }
    .shareclass-name {
        width: calc(100% - 21px);
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
    .cell-keywords {
        width: 100%;
        flex-wrap: nowrap !important;
        overflow: scroll;
        scrollbar-width: none;
        div {
            text-wrap: nowrap !important;
        }
    }
}

.panel-summary-captable {
    .captable-summary-table {
        .table-row {
            &:last-child .cell-content {
                font-weight: bold;
            }
        }
        &.unassigned {
            .table-row {
                &:nth-last-child(3) {
                    border-bottom: 1px dashed #E2E8F0 !important;
                }
                &:nth-last-child(2) .cell-content {
                    font-style: oblique;
                }
            }
        }
    }
}

.chart-panel-actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding: 25px;
    height: 24px;
    box-sizing: content-box;
    svg {
        font-size: 20px;
        color: var(--primary-color);
        transition: transform 0.1s;
        &:hover {
            transform: scale(1.2);
            cursor: pointer;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .captable-features {
        grid-template-columns: repeat(2, 1fr);
    }
    .setup-captable {
        gap: 50px;
    }

    .panel-summary-shareClass {
        .chart-panel-actions { 
            padding: 15px;
        }
        .rows-number {
            display: none;
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .setup-captable {
        flex-direction: column;
        gap: 20px;
        .setup-captable--actions {
            width: 100%;
        }
    }
    .captable-features {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: $breakpoint-lg) {
    .panel-summary-captable {
        .panel-heading {
            height: 80px;
            overflow: visible;
        }
    }
}