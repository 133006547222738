@import 'src/styles/_colors';
.question{
    // background-color:var(--primary-light-color);
    border-radius: 10px;
    .question-header{
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #B5B5B5;
        padding-bottom: 15px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .question-header-avatar{
            padding-right: 10px;
        }
        .question-header-name{
            font-weight: 400;
            font-size: 16px;
            p{
                margin: 0;
            }
        }
    }
    .question-body{
        h6{
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0.5rem;
        }
        p{
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }
}
