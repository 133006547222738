@import 'src/styles/colors';
@import 'src/styles/variables';

.form-signup,
.form-login{
  .goto-recovery-password{
    margin-bottom: 30px;
    margin-top: -25px;
  }
  .form-actions{
    button{
      width: 100%;
    }
  }
  .social-actions{
    margin-bottom: $marginListBetween;
    .social-buttons{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
    .separator{
      position: relative;
      display: flex;
      margin: $marginListBetween 0;
      align-items: center;
      justify-content: center;
      span{
        font-size: 12px;
        color: $greyLight;
        line-height: 1;
        margin: 0;
        padding: 0 15px;
        background-color: #fff;
        z-index: 1;
      }
      &::after{
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: 6px;
        height: 1px;
        background-color: $greyLight;
      }
    }
  }
  .goto-link{
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}