@import 'src/styles/breakpoints';
@import 'src/styles/colors';

.startup-panel {
  .startup-panel--header {
    display: flex;
    align-items: center;
    width: calc(100% - 190px);
    .startup-panel--title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      gap: 5px;
      height: 65px;
      .startup-panel--name,
      .startup-panel--legalname,
      .startup-panel--country {
        line-height: 1.1;

        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
      .startup-panel--name {
        font-weight: bold;
        font-size: 16px;
        color: $text-color;
      }
      .startup-panel--legalname {
        font-size: 12px;
        color: $textColorLight;
      }
      .startup-panel--country {
        font-size: 12px;
        color: $textColorLight;
        svg {
          font-size: 10px;
          min-width: 10px;
          margin-right: 5px;
        }
      }
    }
  }
  .startup-panel--description {
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .startup-panel--social {
    margin-top: 8px;
    img {
      margin-right: 5px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .startup-panel {
    .startup-panel--title {
      width: calc(100% - 50px);
    }
  }
}