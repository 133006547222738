@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.home-startup{
  .home-startup-panels{
    .startup-panel {
      height: 100%;
      .panel{
        height: calc(100% - 25px);
      }
    }
    .summary-dataroom {
      height: calc(100% - 25px);
      .summary-dataroom-grid {
        &.row {
          row-gap: 20px !important;
        }
        .bookmark-box {
          display: flex;
          align-items: center;
          min-width: 30%;
          height: 24px;
          .text-overflow {
            @include textEllipsis;
          }
          cursor: pointer;
          .folder-icon {
            position: relative;
            display: flex;
            align-items: center;
            width: 35px;
            .folder-icon--type{
              position: absolute;
              top: 4px;
              left: 7px;
            }
          }
          .bookmark-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 15px;
          }
        }
        .empty-folder {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            display: none;
          }
        }
      }
    }

    .summary-captable{
      .panel-empty-message{
        img{
          max-height: 109px;
        }
      }
      .chart-container {
        display: flex;
      }
      .my-position {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 15px;
        border: 1px solid $gray;
        min-width: 250px;
        flex: 0 0 250px;
        z-index: 1;
        background-color: white;
        h5 {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 1;
        }
        div:not(:last-child) {
          margin-bottom: 13px;
        }
      }
      .my-position-arrow {
        z-index: 0;
        position: absolute;
        top: 64px;
        right: 145px;
        height: 50px;
        width: calc(100% - 200px);
        border-top: 1px solid $gray;
        border-right: 1px solid $gray;
        border-bottom: 0;
      }
      .chart  {
        flex-grow: 1;
        .echarts-for-react {
          position: relative;
          z-index: 2;
        }
        &.line {
          .echarts-for-react:before {
            z-index: 0;
            content: '';
            position: absolute;
            top: -10px;
            right: calc(50% - 2px);
            width: 50%;
            background: white;
            height: 38px;
            border-right: 1px solid $gray;
          }
        }
      }
    }
    
    .summary-mentorings-mentors,
    .summary-mentorings-meetings{
      .mentor-card-wrapper,
      .meeting{
        width: calc(100% - 6px);
        margin-bottom: 6px;
      }
      .empty-message{
        margin: 75px 0;
      }
    }
  }
  .panel-empty-home{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
      width: 250px;
      margin: 15px auto;
    }
  }
}

@media (max-width: $breakpoint-lg){
  .home-startup{
    .home-startup-panels{
      & > * {
        flex: 0 1 100% !important;
        padding-left: 0 !important;      
      }
      
      .summary-profile{
        p{              
          min-height: unset !important;
          -webkit-line-clamp: 4;
        }
      }
      .summary-captable{      
        .my-position {
          min-width: 125px;
          flex: 0 0 125px;
          h4 {
            font-size: 16px;
          }
        }
        .my-position-arrow {
          z-index: 0;
          position: absolute;
          top: 64px;
          right: 85px;
          height: 50px;
          width: calc(100% - 200px);
          border-top: 1px solid $gray;
          border-right: 1px solid $gray;
          border-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md){
  .home-startup .home-startup-panels{
    .summary-dataroom {
      .summary-dataroom-grid {
        .bookmark-box {
          width: calc(50% - 10px);
        }
      }
    }
    .summary-captable{  
      .chart-container {
        flex-direction: column;
        gap: 20px;
      } 
      .my-position {
        .my-position-stats{
          display: flex;
          flex-wrap: wrap;
          column-gap: 30px;
          div {
            min-width: 0px;
          }
        }
      }  
      .my-position-arrow {
        display: none;
      }
      .line .echarts-for-react:before {
        top: unset !important;
        bottom: -20px !important;
      }
    }
  }
}

@media (max-width: $breakpoint-sm){
  .home-startup .home-startup-panels{
    .summary-dataroom {
      .summary-dataroom-grid {
        .bookmark-box {
          width: 100%;
        }
      }
    }
  }
}