@import 'src/styles/variables';

.invite-founder-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  .invite-founder-button.btn-secondary{
    min-width: auto;
    max-width: 60px;
    width: auto;
    height: 60px;
    border-radius: 30px;
    transition: max-width 0.5s;
    overflow: hidden;
    text-wrap: nowrap;
    padding: 0;
    box-shadow: $boxShadowMedium;

    span {
      margin: 0 17px;
    }
    svg {
      position: relative;
      left: 0;
      top: 0;
      width: 23px !important;
      height: 40px;
      margin-left: 10px;
      filter: drop-shadow($boxShadowLight);
    }
  
    &:hover {
      background-color: white !important;
      opacity: 1 !important;
      border-radius: 30px;
      max-width: 400px;
      transition: max-width 1s;
    }
  }
}