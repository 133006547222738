@import 'src/styles/breakpoints';
@import "src/styles/_colors";
@import 'src/styles/variables';

.myPosition-wrapper {
  .table-row {
    background-color: white;
  }
}
.position-valuation--title {
  text-align: center;
  font-weight: bold;
}
.position-valuation--value {
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
}

.position-overview--legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  li {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 5px 0;
    .position-overview--legend-circle {
      width: 10px !important;
      height: 10px !important;
      border-radius: 50%;
      margin-right: 10px;
    }
    .position-overview--eye {
      color: $gray;
      width: 20px;
      margin-left: 10px;
      &.selected {
        color: var(--primary-color)
      }
    }
    &.hover, &:hover, &.selected {
      * { font-weight: bold }
    }
    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
  }
}

.position-overview--summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .position-overview--summary-potential {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.shareholders-list-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  .shareholders-select {
    width: 30%;
  }
}

@media (max-width: '768px') {
  .position-overview--summary {
    align-items: center;
    margin-bottom: 20px;
  }

  .shareholders-list-wrapper {
    flex-direction: column;
    & > * {
      &:not(svf.gear-icon) {
        width: 100% !important;
      }
    }
    .input-select-wrapper {
      width: 80%;
      margin-bottom: 0;
    }
  }
}