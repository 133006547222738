@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/variables';

.popup-profile{
  .popup-profile-header{
    display: flex;
    align-items: flex-start;
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid $borderGrey;
    & > div:first-child{
      margin-right: 10px;
    }
    & > div:last-child{
      & > :last-child{
        margin-bottom: 0;
      }
      svg{
        margin-right: 5px;
      }
    }
  }
  .popup-profile-extra{
    word-break: break-all;
  }
}


@media(max-width: $breakpoint-sm){
  .popup-profile{
    .popup-profile-header{
      flex-direction: column;
      & > div{
        width: 100%;
        &:first-child{
          text-align: center;
        }
      }
    }
  }
}