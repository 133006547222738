@import 'src/styles/_colors';

.box{
  @include box;
}

.box-padding{
  padding: $boxPadding;
}
.box-mb{
  margin-bottom: $marginListBetween;
}
.box-background{
  padding: $boxPadding;
  background-color: var(--primary-color-15);
  border-radius: $borderRadius;
}

.mb-lb{ margin-bottom: $marginListBetween !important; }
.mt-lb{ margin-top: $marginListBetween !important; }
.mt-sp{ margin-top: $spacing; }
.mb-sp{ margin-bottom: $spacing; }

.bs-regular{ box-shadow: $boxShadow;}
.bs-medium{ box-shadow: $boxShadowMedium;}
.bs-light{ box-shadow: $boxShadowLight;}
.bs-none{ box-shadow: none;}
.fc-icon{ color: $icon-color; }

.keyword-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .keyword{
    background-color: var(--primary-color-75);
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    color: white;
    margin: 5px;
  }
}

.scroll-horizontal{
  overflow-x: auto;
  padding: 10px;
  .scroll-horizontal-width{
    display: table;
    min-width: 100%;
    .scroll-horizontal-list{
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.profile-inline{
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  .avatar{
    margin-right: 5px;
  }   
}

.list-separator{
  & > *{
    border-bottom: 1px solid var(--primary-color-15);
    padding-bottom: 25px;
    margin-bottom: 25px;
    &:last-child{
      border-bottom: none;
      margin-bottom: 0px;
    }
  }
  .list-row-delete{
    position: relative;    
    padding-right: 35px;
    .delete-row{
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
  }
  .delete-row{
    cursor: pointer;
  }
}

.simple-scrollbar{
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: $greyLight;
  }
}

.swiper {
  &.swiper-pagination-below{
    .swiper-pagination{
      position: relative;
      bottom: unset;
      margin-top: 10px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .sm-flex-column{ /* para evitar el mismo nombre que boostrap */
    flex-direction: column !important;
    width: 100% !important;
    & > * {
      width: 100% !important;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .mb-md-0{
    margin-bottom: 0 !important;
  }
}
@media (min-width: $breakpoint-lg) {
  .mb-lg-0{
    margin-bottom: 0 !important;
  }
}