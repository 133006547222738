@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.payouts-collapse,
.shares-collapse,
.options-collapse,
.convertible-collapse{
  .ccollapse-header {
    align-items: center;
    height: 80px;
    .collapse-header--flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .options-slider,
      .input-select-wrapper  {
        margin: 0;
        width: 200px;
        height: 50px;
      }
      .options-slider {
        width: 230px;
        position: relative;
        top: 20px;
        left: 10px;
        .slider__min_max {
          display: none;
        }
        .thumb {
          top: -10px;
        }
      }
    }
  }
  .no-results {
    padding: 10px !important;
    & > p {
      margin: 0;
    }
  }
  .table-component {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    .table-cell .input_number, .actions-container {
        margin: 0 !important;
    }
    .table-cell .pre_text,
    .no-results img,
    .table-pagination {
      display: none;
    }
    .table-top {
      margin-bottom: 10px !important;
    }
  }
  .disabled-checkbox {
    cursor: auto;
    svg {
      fill: $gray;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .payouts-collapse,
  .shares-collapse,
  .options-collapse,
  .convertible-collapse{
    .ccollapse-header {
      height: 100px;
      .collapse-header--flex {
        flex-direction: column;
        align-items: flex-start;
        .input-select-select  {
          margin-top: 7px;
          padding: 0 10px;
          height: 35px !important;
        }
        .input_slider {
          left: -10px;
        }
      }
    }
  }
}