@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.prehome_container {
  .startup {
    @include box;
    overflow:hidden;
    width: 100%;
    background: $white;
    box-shadow: $boxShadowLight;
    padding: $boxPadding;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.accepted:hover {
      cursor: pointer;
      box-shadow: $boxShadowMedium;
      background-color: var(--primary-light-color);
    }
    &.pending {
      height: 195px;
    }
    .company_header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      .company_info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        gap: 5px;
        .company_name{
          line-height: 1;
          font-weight: bold;
          font-size: 16px;
          color: $text-color;
        }
        .company_legal_name {
          line-height: 1;
          font-size: 12px;
          color: $textColorLight;
        }
        .company_country{
          display: flex;
          align-items: center;
          line-height: 1;
          font-size: 12px;
          color: $textColorLight;
          svg {
            position: relative;
            top: -0px;
            font-size: 10px;
            margin-right: 5px;
          }
        }
      }
    }
    .company_social {
      margin-bottom: 5px;
      img {
        margin-right: 3px;
      }
    }
    .company_description { 
      height: 45px;       
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: $text-color;
      font-size: 14px;
      margin-bottom: 5px;

    }
    .company_keywords{
      display: inline-flex;
      flex-wrap: wrap;
      div{
        line-height: 1.1;
        font-style: italic;
        border-radius: 12px;
      }
    }
    .company_buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
  &.pending {
    margin-bottom: 50px;
    .table-pagination {
      display: none;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .prehome_container {
    .buttons .viewMyPosition-button {
      display: none;
    }
  }
}
