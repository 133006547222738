@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.create-fundraise {
  .status-row {
    display: flex;
    align-items: center;
    .input_toggle {
      margin-bottom: 0;
    }
  }

  .input_radio .input_options {
    display: flex;
    gap: 100px
  }
}

.inputKPIs-box{
  position: relative;
  cursor: pointer;
  .inputKPIs-button {
    position: absolute;
    bottom: 0px;
    right: 0px; 
    .button-app {
      width: 40px;
      height: 40px;
      border-radius: 0 $borderRadiusExtraLight $borderRadiusExtraLight 0;
    }
  }
}

.table_kpi .table-rows {
  svg.disabled {
    color: $darkGray;
  }
}

@media (max-width: 575px) {
  .status-row {
    .input_toggle {
      margin-bottom: 20px !important;
    }
  }
}