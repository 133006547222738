@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.downloading-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 250px;
    margin: 15px auto;
  }
}