@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.startup-fundraise-view {
    position: relative;
    
    .document {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        svg {
            margin-right: 7px;
            min-width: 30px;
            font-size: 30px;
        }
        .document-name {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:hover .document-name {
            font-weight: 700;
        }
    }

    .my-investment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .my-investment--amount {
            font-weight: 700;
            margin-bottom: 0;
        }
        .my-investment--actions {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $svgColor;
            svg {
                font-size: 20px;
                cursor: pointer;
                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }
    .fundraise-status {
        top: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        display: flex;
        justify-content: right;
        .fundraise-status-keyword {
            background-color: #E0E0E0;
            padding: 4px 10px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 12px;
        }
    }
}
.invite-emails {
    position: relative;
    .pre_text {
        display: none;
    }
    .trash-icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 0px;
        bottom: 0px;
        margin: auto;
    }
}

.investment-notes {
    width: 95%;
    & > * {
        @include textEllipsis;
        margin-bottom: 0;
    }
}

@media (min-width: 800px){
    .buttons-absolute {
        position: absolute;
        top: 3px;
        right: 0;
    }

    .fundraise-status {
        position: absolute;
        right: 0;
    }
}