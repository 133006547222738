@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';


.services-wrapper {
  .service {
    @include box;
    overflow:hidden;
    background: $white;
    box-shadow: $boxShadowLight;
    padding: $boxPadding;
    height: 170px;
    cursor: pointer;
    &:hover {
      box-shadow: $boxShadowMedium;
      background-color: var(--primary-light-color);
    }
    .company_header {
      position: relative;
      display: flex;
      align-items: center;
      .company_name{
        font-weight: bold;
        font-size: 16px;
        color: $text-color;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px
      }
      .company_type {
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px
      }
    }
    .company_info {
      width: 100%;
      height: 70px;
      padding: 10px 0;        
      .company_description {
        color: $text-color;
        display: block;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .company_status{
      span {
        line-height: 1;
        background-color: #E0E0E0;
        padding: 7px 10px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 12px;
      }
    }
  }
}
.service-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .service-info {
    display: flex;
    align-items: center;
    .service-info--name {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      * {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }
  .service-status {
    margin-right: 20px;
  }
}

@media (max-width: $breakpoint-sm) {
  .service-header { 
    display: block !important;
    .service-status, .btn {
      margin: 25px 0px 0px;
    }
  }
}
