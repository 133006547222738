@import 'src/styles/colors';

.questions-wrapper {
  .first-question-wrapper {
    justify-content: flex-start;
    align-items: center;
    .input_file {
      width: 140px;
      height: 100px;
      margin-right: 29px;
      .input_file_img {
        margin-left: 0 !important;
      }
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
  }
}

.go-home-button {
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  img {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.go-home-button:hover {
  font-family: $bold;
}

@media (max-width: 600px) {
  .edit-account-wrapper {
    padding: 30px !important;
  }
}
