@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

$minHeight: 630px;

.home-viewport{
  display: flex;
  width: 100%;
  height: $toolViewHeight;
  min-height: $minHeight;
  .home-left-section{
    width: 325px;
    padding-right: 25px;
    min-height: $minHeight;
    .panel{
      height: calc(100% - #{$spacing});
    }
  }
  .home-right-section{
    width: calc(100% - 350px);
  }
}
.home-wrapper{
  .profile-card-info{
    .profile-card-body{
      max-height: calc(#{$toolViewHeight} - 275px);
      height: calc(#{$toolViewHeight} - 275px); // 50(padding)+25(margin)+140(avatar)+60(button)
      min-height: 200px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
  .empty-message{
    text-align: center;
    margin: 35px 0;
    font-style: italic;
  }
}
.panel-chart {
  padding: 0 !important;
  height: 400px;
  .pie-chart {
    height: 340px;
  }
}

.home-mentor-wrapper {
  .home-right-section{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    height: 100%;
    .col-stats,
    .col-double{
      width: 100%;
    }
    .col-double{
      display: flex;
      height: calc(#{$toolViewHeight} - 141px); //menos la altura de los stats
      .col-meetings,
      .col-documents{
        width: 50%;
        .panel{
          height: 100%;
          .panel-body{
            max-height: calc(#{$toolViewHeight} - 227px);
            overflow-y: auto;
          }
        }
      }
      .col-meetings{
        padding-right: calc(#{$spacing} / 2);
      }
      .col-documents{
        padding-left: calc(#{$spacing} / 2);
      }
    }
  }
}

.home-startup-wrapper {
  .tool-content {
    .panel {
      width: 100%;
      h1.panel-title {
        font-size: 20px;
        line-height: 24px;
        color: #2e2e2e;
      }
      p.panel-description {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      .kpis-wrapper {
        .kpis-chart-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .table-component {
            margin-left: 20px;
            width: calc(50% - 20px);
          }
        }
      }

      .meetings-wrapper,
      .mentors-wrapper {
        .meetings-cards-list,
        .mentors-cards-list {
          li.meeting,
          .mentor-card-wrapper {
            margin-right: 20px;
            width: 400px;
          }
        }
        .empty-message{
          margin: 75px 0;
        }
      }
      .performance-summary{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & > div{
          width: 50%;
        }
      }
    }
    .home-right-section{
      & > .panel{
        height: calc(50% - #{$spacing});
        overflow: auto;
      }
    }
  }
}

.summary-myPosition {
  display: flex;
  justify-content: space-between;
  div {
    flex: 1;
    &:not(:first-child){
      margin-left: 20px;
    }
  }
}

@media (max-width: 1200px){
  .home-viewport{
    width: 100%;
    flex-direction: column;
    height: auto;
    .home-left-section{
      width: 100%;
      padding-right: 0;
    }
    .home-right-section{
      width: 100%;
    }
  }
}
@media (max-width: $breakpoint-md){
  .home-wrapper{
    .profile-card-info{
      .profile-card-body{
        height: auto;
        min-height: auto;
      }
    } 
  }
  .home-mentor-wrapper {
    .home-right-section{
      .col-double{
        flex-direction: column;
        height: auto;
        .col-meetings,
        .col-documents{
          width: 100%;
          padding: 0;
          .panel{
            height: auto;
            .panel-body{
              max-height: 300px;
            }
          }
        }
      }
    }
  }
  .panel-chart {
    height: 385px;
    .pie-chart {
      height: 330px;
    }
  }
  .home-startup-wrapper{
    .tool-content{
      .home-right-section{
        & > .panel{
          height: auto;
        }
      }      
    }
  }
}

.requestAccess-wrapper {
  .requestAccess-next-step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .requestAccess-form-wrapper {
      padding: 0 10px;
      width: $breakpoint-md;
      max-width: 100%;
    }
  }
  .step-title{
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }
  .step-message{
    text-align: center;
    margin-bottom: 25px;
  }
  .step-svg {
    max-width: 100%;
    margin: 25px 0px;
    height: 180px;
  }
}

@media (max-width: $breakpoint-sm) {
  .summary-myPosition{
    display: flex;
    flex-direction: column;
    div {
      flex: 1;
      &:not(:first-child){
        margin-left: 0;
      }
    }
  }
  .panel-chart {
    height: 385px;
    .pie-chart {
      height: 330px;
      top: 30px !important;
    }
  }
  .requestAccess-wrapper {
    .buttons {
      flex-direction: column;
      * {
        width: 100%;
      }
    }
  }
}