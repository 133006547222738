@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

@mixin folder-selected{
  border-top-left-radius: $borderRadiusLight;
  border-bottom-left-radius: $borderRadiusLight;
  background-color: var(--primary-light-color);
  .folder-name{
    font-weight: bold;
  }
}

.selected-folder{
  flex: auto;
  overflow-x: hidden;
  padding: 0px 10px;
  .content-container {
    height: calc(100vh - 389px);
    overflow-y: auto;
    .loading-page {
      display: flex;
      align-items: center;
      justify-content: center;
      .dialog--loading {
        display: inline;
        width: auto;
        padding: 0;
        .loading {
          height: 30px;
          width: 30px;
        }
      } 
    } 
  }
}

.file-previous-versions {
  .file-previous-versions--timeline {
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 10px;
    transition: all 0.4s;
    .file-previous-version{
      color: $textColor;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .bt-open, .bt-close {
        color: $svgColor;
        visibility: hidden;
      }
      &:hover{
        .file-version--name{ 
            font-weight: bold;
        }
        .file-version--icon{
            background-color: var(--primary-color);
            svg{ color: #fff }
        }
        .bt-open, .bt-close {
          visibility: visible;
        }
      }
      &:not(:last-child):after{
        position: absolute;
        content:'';
        width: 1px;
        height: 30px;
        left: 25px;
        top: 44px;
        background-color: var(--primary-color);
        svg{ background-color: var(--primary-color) }
      }
      .file-version--icon{
        border-radius: 50%;
        border: 1px solid var(--primary-color);
        background-color: #fff;
        width: 31px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .file-version--content{
        padding-left: 10px;
        .file-version--name{
            margin: 0;
            padding-bottom: 1px;
            line-height: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 225px;
        }
      }
    }
  }
  .file-previous-versions--showMore {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    svg {
      transition-property: transform;
      transition-duration: 0.3s;
      margin-left: 5px;
      &.rotateSvg {
        transform: rotate(180deg);
      }
    }
  }
}

.dataroom-container{
  display: flex;
  .folders-tree{
    position: relative;
    flex: none;
    width: 225px;
    height: auto;
    margin-right: 30px;
    transition: width 0.3s;
    border-right: 1px solid $greyLight;
    height: calc(100vh - 235px);
    overflow-y: scroll;
    &.move-tree{
      margin: 0;
      width: 100%;
      border: none;
    }
    .container-list{
      overflow-x: hidden;
      overflow-y: auto;
      li{
        list-style: none;
      }
    }
    .li-container{
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
      }
      .folder-name{
        display: inline-block;
        width: calc(100% - 78px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 0px;
        cursor: pointer;
      }  
      .dots-menu{
        visibility: hidden;
        .bt-open, .bt-close {
          height: 20px;
          width: 20px;
        }
      }
    
      &:hover{
        border-top-left-radius: $borderRadiusLight;
        border-bottom-left-radius: $borderRadiusLight;
        background-color: #f5f5f5;
        .dots-menu{
          display: inline-block;
          visibility: visible;
          padding-left: 10px;
        }
      }

      &.selected{
        @include folder-selected;
      }
      &.moving{
        @include folder-selected;
        border: 1px solid var(--primary-color);
      }
    }
    .disabled{
      pointer-events: none;
      opacity: 0.7;
    }
  }
  .open-tree, .close-tree{
    display: none;
  }
  .root{
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 5px;
    cursor: pointer;
    margin: 0;
    .folder-name {
      display: inline-block;
      width: calc(100% - 55px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.selected{
      @include folder-selected;
    }
  }
}

.folder{
  h5, hr{
    margin: 3px 0px;
  }
  .buttons{
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 50px;
    margin-bottom: $marginListBetween;
    &.buttons-skeleton{
      position: relative;
      top: -20px;
      margin: 0;
    }
  }
  .folder-breadcrumb{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    &.breadcrumb-skeleton{
      position: relative;
      top: -13px;
      margin: 0;
    }
  }
  .folder-searcher {
    display: flex;
    margin-bottom: 15px;
    & > .input_text {
      display: flex;
      align-items: center;
      max-width: 400px;
      margin: 0;
      input{
        background-color: white;
        box-shadow: $boxShadowLight;
        height: 45px;
        flex: 1 0px;
      }
      .btn-search-mobile{
        height: 45px;
        width: 45px;
        min-width: 45px;
        display: none;
        align-items: center;
        justify-content: center;
        padding: 0;
        box-shadow: $boxShadowLight;
        border-radius: 0 $borderRadiusMedium $borderRadiusMedium 0;
        span{
          display: none;
        }
      }
    }
    .search-submit {
      flex: none;
      button {
        min-width: auto;
        margin-left: 10px;
        height: 45px;
        box-shadow: $boxShadowLight;
      }
    }
    &.searcher-skeleton {
      position: relative;
      top: -15px;
      margin-bottom: 0;
    }
  }

  .folder-folders, .folder-content {
    margin-bottom: $marginListBetween;
    .li-container{
      padding: calc($marginListBetween / 2) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .folder-info{
        cursor: pointer;
      }
      .content-left{
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .content-info{
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      svg:not(.avatar-svg):not(.permission-icon){
        color: $darkGray
      }
      .folder-icon {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 50px;
        .folder-icon--type{
          position: absolute;
          top: 10px;
          left: 10px;
        }
      }
      .folder-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .views {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          margin-right: 12px;
        }
        .dots-menu{
          li {
            margin: 0 !important;
          }
          visibility: hidden;
        }
        .bookmark-icon {
          cursor: pointer;
          margin: 0px 5px;
          svg {
            font-size: 18px;
            color: var(--primary-color);
          }
          &.bookmark-icon-loading svg{
            color: $svgColor;
          }
        }
      }
      &:hover{
        background-color: #f5f5f5;
        .dots-menu{
          visibility: visible;
        }
      }
    }
    &.folders-skeleton{
      position: relative;
      top: -15px
    }
  }

  .swiper-bookmark {
    .bookmark-box {
      @include box;
      padding: $boxPadding;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: $marginListBetween 5px;
      .text-overflow {
        @include textEllipsis;
        padding-right: 20px;
      }
      .folder-icon {
        position: relative;
        display: flex;
        align-items: center;
        width: 35px;
        .folder-icon--type{
          position: absolute;
          top: 4px;
          left: 7px;
        }
      }
      .bookmark-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 15px;
      }
    }
    .swiper-pagination {
      margin: -5px !important;
    }
  }
  .empty-folder {
    text-align: center;
    padding: 60px 0;
    img{
      margin-bottom: 15px;
      width: 350px;
      max-width: calc(100% - 50px);
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-default{
  cursor: pointer;
}

.folder-placeholder{
  opacity: 0.5;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  &.subfolder {
    padding-left: 0px; 
    height: 50px;
  }
  &.content {
    padding-left: 0px; 
    height: 45px;
  }
}

.folder-dropzone{
  position: relative;
  height: 44px;
  width: 100%;
  display: none;
  &.active{
    display: block;
  }
  & > div{
    display: none !important;
  }
  span{
    font-size: 12px;
    display: block;
    line-height: 1;
    border: 1px dashed $greyLight;
    width: calc(100% - 20px);
    margin-left: 10px;
    padding: 10px;
  }
}

.droppable {
  border: 1px solid transparent;
  &.droppable-selected{
    background-color: var(--primary-color-25);
    border: 1px solid var(--primary-color);
    border-radius: $borderRadiusLight;
  }
  .placeholder{
    height: 0;
  }
}

.ext {
  position: absolute;
  right: 30px;
  top: 43px;
}

.file-actual-version {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 10px 10px 5px;
  .file-info {
    color: $textColor;
    svg {
      font-size: 42px;
      margin: 0 10px 0 0px;
    }
  }
  .bt-open, .bt-close {
    color: $svgColor;
    visibility: hidden;
  }
  &:hover {
    background-color: var(--primary-light-color);
    .file-name {
      font-weight: 700;
    }
    .bt-open, .bt-close {
      visibility: visible;
    }
  }
}

.file-info {
  .file-name {
    svg {
      font-size: 35px;
      margin: 0 10px 0 6px;
      color: $darkGray;
    }
  }
}



@media (max-width: 760px) {
  .dataroom-container{
    .open-tree {
      display: block;
      position: relative;
      top: 40px;
      margin-top: -40px;
      .arrow-icon {
        position: absolute;
        top: 12px;
        left: 12px;
      }
    }
    .folders-tree {
      position: absolute;
      background-color: white;
      width: 0px;
      border: none;
      z-index: 2;
      .container-list{
        height: calc(100vh - 316px);
        overflow-y: auto;
        li{
          list-style: none;
        }
      }
      .arrow-icon {
        position: absolute;
        top: 13px;
        right: 26px;
      }
      .close-tree{
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
      }
      .hide {
        display: none;
      }
      &.show{
        display: block;
        width: 250px;
        border-right: 1px solid lightgray;
      }
    }
    .folder-searcher {
      flex-direction: column;
      order: 2;
      & > .input_text {
        max-width: 100%;
        input{
          border-radius: $borderRadiusMedium 0 0 $borderRadiusMedium;
        }
        .btn-search-mobile{
          display: inline-flex;
        }
      }
      & > .search-submit {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .folder {
    .buttons {
      .MuiSkeleton-root {
        width: 48px !important;
        height: 69px !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .folder-searcher {
    & > .input_text {
      input {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
    .search-submit {
      button {
        margin: 0 !important;
        &.btn-outline-primary {
          border-radius: 0 !important;
          border-width: 0 0 0 1px;
          border-color: $borderGrey;
        }
        &.btn-primary {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border: none !important;
        }
      }
    }
  }
}

