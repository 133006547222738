@import "src/styles/colors";
@import "src/styles/variables";
@import "src/styles/breakpoints";

.events-timeline {
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid $borderGrey;
  li {
    position: relative;
    margin-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: -25px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
    }
  }
}

.upload-document-sign {
  .custom-file-upload {
    padding: 11px;
  }
}
