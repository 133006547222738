@import 'src/styles/_colors';

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  color: $text-color;
  background-color: $bodyColor;
}

html,
body,
#root{
  min-height: 100%;
}

input,
select,
textarea {
  outline: none;
}

.hidden,
[hidden] {
  display: none !important;
}

ul,
ol {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none !important;
}
