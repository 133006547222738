//************************** All Mixins ***************************

@mixin box {
    border-radius: $borderRadius;
    background-color: white;
    box-shadow: $boxShadow;
}

@mixin textEllipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin keywords {
    display: inline-flex;
    flex-wrap: wrap;
    height: 32px;
    & > div{
        background-color: #E0E0E0;
        padding: 7px 10px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 12px;
    }
}