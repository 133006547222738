@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.panel{
  @include box;
  margin-bottom: 25px;
  position: relative;

  .panel-heading,
  .panel-body{
    padding: 25px;
  }
  .panel-heading{
    padding-bottom: calc(25px / 2);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-flow: row;
    flex-wrap: nowrap;
    h3,
    h4{
      margin: 0;
    }
    p{
      margin: 5px 0 0 0;
      font-size: 12px;
    }
    .panel-heading-info{
      .main-breadcrumb, a {
        margin-bottom: 0;
      }
    }
    .panel-heading-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      z-index: 1;
      &.noTitle {
        width: 100%;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        &.justify-end{
          justify-content: flex-end;
        }
        &.justify-between{
          justify-content: space-between;
        }
      }
      .panel-heading-tabs-selector{
        display: none;
        width: 100%;
        .input-select-wrapper{
          margin-bottom: 0px;
          .input-select-select {
            span, svg {
              font-size: 16px;
              color: $white;
            }
            background-color: var(--primary-color);
            .selected-item{
              padding-left: 25px;
              text-transform: uppercase;
            }
        }
      }
      }
      .panel-heading-tabs{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $borderRadius;
        background-color: $lightGrey;
        border: 1px solid $borderGrey;
        overflow: hidden;
        padding: 4px;
        div{
          padding: 8px 12px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          white-space: nowrap;
          &:not(:first-child) {
            margin-left: 1rem;
          }
          
          &:hover, &.active{
            background-color: white;
            border-radius: $borderRadiusMedium;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
          }
        }
      }
      .panel-heading-actions {
        display: flex;
        .button-dropdown-menu {
          z-index: 6;
        }
      }
      &.noBody{
        padding-bottom: 25px;
      }
    }
  }
  .panel-body{
    padding-top: calc(25px / 2);
    &.noHeader{
      padding-top: 25px;
    }
    .panel-actions-right{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      & > *{
        margin-bottom: 10px;
      }
    }
    .panel-fix-rigth-button{
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 1;
      button{
        box-shadow: $boxShadowLight;
      }
    }
  }
  .panel-empty-message{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &:not(.m-0){
      margin: 50px 0;
    }
    img{
      width: 250px;
      height: auto;
      max-height: 150px;
      max-width: calc(100% - 50px);
    }
    &.full{
      img{
        width: 300px;
        max-height: 300px;
      }
    }
    p{
      margin: 15px 0 0 0;
    }
    a{
      margin-top: $spacing;
    }
  }
}

@media(max-width: $breakpoint-md){
  .panel{
    .panel-heading{
      flex-direction: column;
      .panel-heading-right{
        width: 100%;
        height: min-content;
        margin-top: 15px;
        .panel-heading-tabs{
          display: none;
        }
        .panel-heading-tabs-selector{
          margin-top: 10px;
          display: block;
        }
        .panel-heading-actions { 
          flex-direction: column;
          width: 100%;
          margin: 0;
          button {
            margin: 3px 0px !important;
            width: 100%;
          }
        }

      }
    }    
    .panel-body{
      .panel-actions-right{
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
      }
    }    
    .panel-heading,
    .panel-body{
      padding: $spacingSM;
      .panel-fix-rigth-button{
        position: relative;
        top: 0;
        right: 0;
        margin: -10px 0 25px 0;
        display: flex;
        justify-content: flex-end;
      }
    }
  }    
}