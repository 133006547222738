@import 'src/styles/colors';

.NotFound {
  text-align: center;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    display: inline-block;
    border-right: .1rem solid $black;
    margin: 0;
    margin-right: 2.0rem;
    padding: 1.0rem 2.3rem 1.0rem 0;
    font-size: 2.4rem;
    font-weight:500;
    vertical-align:top;
  }
  h2 {
    font-size:1.4rem;
    font-weight: 300;
    line-height: inherit;
    margin:0;
    padding:0
  }
  .content {
    display:inline-block;
    text-align:left;
    line-height: 4.9rem;
    height: 4.9rem;
    vertical-align:middle;
  }
  a {
    display: block;
    margin-top: 25px;
  }
}
