@import 'src/styles/_colors';
@import 'src/styles/_variables';
.info{
    padding: $boxPadding;
    .info-header{
        display: flex;
        align-items: flex-start;
        margin: 10px 0px 25px;
        border-bottom: 1px solid #B5B5B5;
        .info-header-avatar{
            margin-right: 10px;
        }
    }
    .info-body{
        h6 {
            margin: 0;
        }
        p{
            margin-top: 0;
            margin-bottom: 1rem;
        }
        .input_stars {
            margin-bottom: 0px;
            .pre_text{
                display: none;
            }
            .input_star{
                margin: 0px 10px 0px 0px;
            }
        }
    }
}
.reason{
    text-align: center;
    background-color: $darkRed;
    border-radius: $borderRadius;
    color: $white;
    padding: $boxPadding;
    margin-bottom: $spacing;
    p{
        margin: 0;
        padding: $boxPadding;
    }
}
.noFeedback{
    text-align: center;
    h2{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
}