@import 'src/styles/variables';

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog-content {
    background-color: #F6F6F6;
    padding: 25px;
    border-radius: 5px;
    max-width: 500px;
    text-align: left;
    min-width: 400px;
    box-shadow: $boxShadow;
    h5{
      margin-bottom: 25px;
    }
    p{
      display: block;
      margin-bottom: 25px;
    }
    .dialog-actions{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      & > button:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 575px){
  .dialog {
    .dialog-content {
      max-width: calc(100vw - 20px);
      min-width: unset !important;
    }
  }
}