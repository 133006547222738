@import 'src/styles/variables';
@import 'src/styles/breakpoints';

$toolViewHeight: calc(100vh - 200px);
$panelViewHeight: calc(100vh - 175px);
$profileViewHeight: calc(100vh - 425px);

.mentoring-wrapper{
  .mentoring-cols{
    display: flex;
    align-items: stretch;
    min-height: $toolViewHeight;
    .col-profile{
      width: 325px;
      margin-right: $marginCols;
      .profile-card-body{
        max-height: $profileViewHeight;
        overflow-y: auto;
      }
    }
    .col-panels{
      flex: 1 0px;
      display: flex;
      height: $panelViewHeight;
      max-height: $panelViewHeight;
      min-height: $panelViewHeight;
      align-items: stretch;
      justify-content: space-between;
      & > *{
        flex: 0 50%;
        &.col-panel-left{
          padding-right: calc(#{$spacing} / 2);
        }
        &.col-panel-right{
          padding-left: calc(#{$spacing} / 2);
        }
      }
      .panel{
        display: block;
        height: 100%;
        margin: 0 !important;
        .panel-body{
          overflow: auto;
          height: calc(100% - 62px);
        }
      }
      .mentoring-reports{
        .mentoring-report{
          padding: 15px;
          position: relative;
          margin-bottom: $marginListBetween;
          cursor: pointer;
          .mentoring-report-actions{
            position: absolute;
            top: 15px;
            right: 10px;
          }
          .avatar{
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-lg){
  .mentoring-wrapper{
    .mentoring-cols{
      flex-direction: column;
      height: auto;
      min-height: unset;
      max-height: unset;
      & > div {
        width: 100% !important;
      }      
      .col-profile{
        margin-right: 0;
        .panel{
          margin-bottom: $spacing;
        }
        .profile-card-body{
          max-height: unset;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md){
  .mentoring-wrapper{
    .mentoring-cols{
      .col-panels{
        height: auto !important;
        max-height: unset !important;
        flex-direction: column;
        & > * {
          padding: 0 !important;
          &:first-child{
            margin-bottom: $spacing;
          }
        }
      }
      .col-panel-left{
        .panel-body{
          .messages{
            max-height: calc(50vh);
          }
        }
      }
      .col-panel-right{
        .panel-body{
          max-height: calc(50vh);
        }
      }
      .profile-card-info{
        .avatar{
          max-width: 75px !important;
          min-width: 50px !important;
          height: auto !important;
          & > *{
            max-width: 50px;
            height: auto;
          }
        }
        .profile-card-details{
          display: none;
        }
      }
    }
  }
}