.invite-user-form {
  .permissions-wrapper {
    .permission-item {
      width: 33%;
      margin-right: 10px;
    }
    .permission-item:last-child {
      margin-right: 0px;
    }
  }
}

.permissions-popup {
  .permission-tool {
    display: flex;
    align-items: center;
    .permission-name {
      font-weight: bold;
      margin-left: 7px;
    }
    svg {
      color: var(--primary-color);
    }
  }
}
