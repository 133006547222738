@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.table-top {
  margin-bottom: 35px;
  .table-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .table-filters-basic {
      flex: auto;
      display: flex;
      align-items: center;
      .table-searcher {
        max-width: 500px;
        .input_searcher {
          margin-bottom: 0;
        }
      }
      .table-filter-submit {
        flex: none;
        button {
          min-width: auto;
          margin-left: 10px;
          height: 45px;
          box-shadow: $boxShadowLight;
        }
      }
    }

    .table-actions{
      float: left;
      display: flex;
      flex-direction: column;
      max-width: 250px;
      padding-left: 15px;
    }
  }
  .table-filters-advanced {
    padding: $boxPadding;
    margin-top: 24px;

    &.hidden {
      display: none;
    }
    button{
      margin-left: 15px;
    }
    .filters-range {
      display: flex;
      gap: 12px;
      .range {
        flex: 1;
        min-width: 0;
      }
    }
  }
}

.table-component {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1;
  .config-columns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
    svg {
      color: $svgColor;
      &.primary {
        color: var(--primary-color);
      }
    }

  }
  .table-overflow {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $greyLight;
    }

    .table-fullwidth{
      display: table;
      border: 1px solid $borderGrey;
      min-width: 100%;
    }
    
    .table-header,
    .table-row {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: nowrap;
      &.selected{
        background-color: var(--primary-light-color)
      }
      &.details {
        display: block;
        position: sticky;
        left: 0;
        top: 0;
        background-color: var(--primary-light-color) !important;
      }
    }

    .table-header {
      padding: 0;
      background-color: $lightGrey;
      border-bottom: 1px solid $borderGrey;
      border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
      .table-header-cell {
        padding: 15px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        .table-header-label {
          font-weight: bold;
          @include textEllipsis;
        }
        &.cursor-pointer{
          cursor: pointer;
        }
        .disabled {
          opacity: 0.5;
        }
        .drag-icon {
          display: flex;
          align-items: center;
          width: 20px;
          color: $svgColor;
        }
      }
    }
    .table-rows {
      .table-row {
        cursor: default;
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
        &:not(:first-child) {
          border-top-width: 0px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid $borderGrey;
        }
        &:hover{
          background-color: $backgroundHover;
        }
        .table-cell {
          position: relative;
          padding: 10px;
          word-break: break-word;
        }
        .drag-icon {
          color: $svgColor;
          &.loading {
            opacity: 0.5;
            cursor: wait;
          }
        }
        .table-row-button {
          background-color: transparent;
          color: var(--primary-color);
          border: none;
          padding: 0;
          margin: 0 auto;
          outline: none;
          height: 26px;
          width: 26px;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.1s;
          border-radius: 50%;
          svg{
            transition: all 0.1s;
            width: 18px;
            height: auto;
          }
          &:hover:not(:disabled) {
            background-color: var(--primary-color-25);
          }
          &:disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
      }
      &.rows-click{
        .table-row {
          cursor: pointer;
        }
      }
      .grid{
        display: grid;
        grid-auto-rows: auto;
        grid-gap: 15px;
        background-color: transparent;
      }
      .no-results-error,
      .no-results {
        text-align: center;
        padding: 35px 0;
        img{
          margin-bottom: 15px;
          width: 350px;
          max-width: calc(100% - 50px);
        }
      }
      .no-results-error{
        img {
          width: 200px;
        }
      }
    }

    .table-header-cell,
    .table-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 0;
      box-sizing: content-box;
      &.fluid {
        min-width: 150px;
      }
      &.wfixed:not(.button-menu){
        & > .cell-content{
          overflow: scroll;
          white-space: nowrap;
          &::-webkit-scrollbar {display: none;}
        }
      }
      &.button-menu{
        padding: 0 !important;
        .buttons-dots{
          margin: 10px;
          width: 10px;
        }
      }
      &.align-center {
        justify-content: center;
        text-align: center;
        .cell-content{
          text-align: center;
          justify-content: center;
        }
      }
      &.align-left {
        justify-content: flex-start;
        .cell-content{
          text-align: left;
        }
      }
      &.align-right {
        justify-content: flex-end;
        .cell-content{
          text-align: right;
        }
      }
      .cell-content{
        width: 100%;
        display: flex;
        align-items: center;
        &.cursor-pointer{
          cursor: pointer;
        }
        .cell-keywords{
          display: inline-flex;
          flex-wrap: wrap;
          padding-top: 5px;
          div{
            line-height: 1;
            background-color: #E0E0E0;
            padding: 7px 10px;
            font-size: 12px;
            margin: 0 5px 5px 0;
            font-weight: bold;
            border-radius: 12px;
          }
          &.sm div{
            padding: 4px 5px;
          }
        }
      }
    }
  }
  .table-pagination {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .pagination-select {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
    .rows-number {
      line-height: 1.5;
      .highlight {
        color: var(--primary-color);
        font-weight: 700;
      }
    }
  }
  &.table-mode-components{
    .table-overflow {
      overflow-x: unset;
      padding: 0px;
      .table-fullwidth{
        border: none;
      }
    }
  }
}

.icon-dropdown-menu{
  background-color: #fff;
  padding: 10px;
  box-shadow: $boxShadowLight;
  .icon-dropdown-item{
    cursor: pointer;
    text-align: left;
    display: block;
    padding: 10px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 60vw;
    line-height: 1;
    color: $text-color;
    svg{
      margin-right: 7px;
      vertical-align: middle;
      color: $text-color;
    }
    &:hover{
      background-color: $lightGrey;
    }
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}

.table-pagination{
  .input-select-wrapper{
    display: inline-block;
    height: 38px;
    margin-bottom: 0px;
    .input-select-item-options{
      top: auto;
      bottom: 10px;
      z-index: 7;
      border-radius: 0;
    }
    .input-select-select {
      padding: 0px 10px;
      background-color: white;
      border: 1px solid $borderGrey;
      height: 38px;
      border-radius: 0;
    }
  }
}

@media (min-width: 768px) {
  .table-top {
    .table-bar{
      .table-filters-basic {
        & > .input_text {
          input {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }
        }
        .table-filter-submit {
          button {
            margin: 0 !important;
            &.btn-outline-primary {
              border-radius: 0 !important;
              border-width: 0 0 0 1px;
              border-color: $borderGrey;
            }
            &.btn-primary {
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
              border: none !important;
            }
          }
        }
      }
    }
  }
}

.config-columns-popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  svg {
    color: $svgColor
  }
}

@media (max-width: $breakpoint-md) {
  .table-pagination {
    flex-direction: column-reverse;
    gap: 10px;
  }
}

@media (max-width: 1245px) {
  .table-top {
    margin-bottom: $spacing;
    .table-bar{
      flex-direction: row;
      padding: 0;
      & > *{
        width: 100% !important;
      }
      .table-filters-basic {
        flex-direction: column;
        order: 2;
        .table-searcher {
          max-width: 100%;
        }
        & > .table-filter-submit {
          display: none;
        }
      }
      .actions-container {
        margin: 0 0 $spacing 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-width: none;
        order: 1;
        .table-actions {
          button{
            margin: 5px;
          }
        }
      }
    }
  }
}
