@import 'src/styles/breakpoints';

.email-stats{
  .stats-boxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    & > *{
      flex: 1 0px;
      padding: 0 10px;
    }
  }
}

.event-actions-stats{
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  li{
    background-color: transparent;
    opacity: 0.6;
    margin: 0 5px;
    padding: 5px;
    font-size: 14px;
    line-height: 1;
    &.active{
      background-color: var(--primary-color-25);
      opacity: 1;
    }
  }
}

.timeline-container {
  position: relative;
  left: 50px;
  margin: 20px auto;
  width: 70%;
  border-left: 1px solid var(--primary-color);
  .timeline-row{
    list-style-type: none;
    position: relative;
    padding: 10px;
    .date{
      position: absolute;
      top: 22px;
      left: -100px;
      font-weight: bold;
      &.year {
        top: -17px;
        left: -90px;
        font-size: 25px;
      }
    }
    .event {
      min-height: 72px;
      margin-left: 20px;
      &.event-year{
        min-height: 0;
      }
      .event-name {
        svg{
          margin-right: 5px;
        }
        span{
          font-weight: bold;
        }
      }
      .event-info{
        li{
          position: relative;
          padding: 0 0 5px 30px;
          overflow: auto;
          &::before{
            content: '-';
            position: absolute;
            left: 8px;
            top: px
          }
          a,
          span{
            word-break: break-all;
          }
        }
        .highlight{
          color: var(--primary-color);
        }
      }
      &::before{
        position: absolute;
        top: 14px;
        left: -26px;
        content: "";
        border-radius: 50%;
        width: 11px;
        height: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 20px;
        padding: 3px;
        background-color: var(--primary-color);
      }
      &.year::before{
        top: -20px;
        left: -29px;
        border: 5px solid var(--primary-color);
        padding: 3px;
        background-color: #fff;
      }
    }
  }
}

@media (max-width: $breakpoint-md){
  .email-stats{
    .stats-boxes{
      justify-content: center;
      & > *{
        flex: 0 30%;
      }
    }
  }
}

@media (max-width: $breakpoint-sm){
  .email-stats{
    .stats-boxes{
      & > *{
        flex: 0 100%;
      }
    }
  }
}