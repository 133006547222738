.shareholders-select {
    display: flex;
    flex: auto;
    align-items: center;
    gap: 10px;

    .input-select-wrapper {
        flex: 1;
        max-width: calc(100% - 35px);
        margin-bottom: 0 !important;
    }
    svg {
        cursor: pointer;
        font-size: 25px;
    }
}