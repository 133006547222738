@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.captable-summary {
  .percent-bars{
    margin-bottom: $marginListBetween;
    .percent-bar{
      display: flex;
      width: 100%;
      .pb-line{
        height: 10px;
        margin-bottom: 5px;
      }
      p{
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:not(:last-child){
        margin-bottom: $marginListBetween;
      }
      & > div:not(:last-child){
        .pb-line{
          border-left: 1px solid #fff;
        }
      }
      & > div:hover{
        .pb-line{
          box-shadow: $boxShadowLight;
        }
        p{
          font-weight: bold;
        }
      }
    }
  }
  .table{
    .table-overflow {
      border: 1px solid $borderGrey;
    }
    .table-header,
    .table-row{
      display: flex;
      position: relative;
      align-items: stretch;
      & > *{
        padding: 10px;
      }
      .row-status{
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        &.row-new{
          background-color: $colorGraphUp;
        }
        &.row-deleted{
          background-color: $colorGraphDown;
        }
      }
      div.cell-name{
        width: 200px;
        flex-grow: 0;
        padding-left: 10px;
        z-index: 1;
        align-items: center;
        display: flex;
      }
      div.cell-columns{
        flex: 1 0px;
        display: flex;
        align-items: center;
        &.pointer {
          cursor: pointer;
        }
        div.cell-value{
          flex: 1 150px;
          z-index: 1;
          svg{
            width: 12px;
            display: inline-block;
            margin-right: 3px;
          }
          span{
            display: block;
            font-size: 12px;
            color: $mediumGray;
          }
        }
        .disabled {
          opacity: 0.5;
        }
      }
      .cell-color{
        position: absolute;
        height: 100%;
        width: 5px !important;
        left: 0;
        top: 0;
      }
      .row-bottom-bar{
        position: absolute;
        bottom: 0;
        height: 100%;
        left: 0;
        z-index: 0;
        opacity: 0.1;
      }
    }
    .table-header{
      z-index: 2;
      & > *{
        background-color: $lightGrey;
        font-weight: 700;
        .cell-value {
          font-weight: 700;
        }
      }
    }
    .table-rows{
      .pointer {
        cursor: pointer;
      }
      .table-row:not(:first-child){
        border-top: 1px solid var(--primary-color-25);
      }
      .table-row:not(.tr-total):hover{
        background-color: var(--primary-color-15);
      }
      .tr-unassigned{
        border-top-style: dashed !important;
      }
      .tr-total{
        border-top-width: 2px!important;        
      }
    }
  }
}

@media (max-width: $breakpoint-md){
  .captable-summary {
    .table{
      overflow-x: auto;
      .table-overflow{
        min-width: 900px;
        .table-header,
        .table-row{
          .cell-name{
            width: 150px;
            position: sticky;
            left: 0;
            z-index: 5;
            box-shadow: 6px 0 10px 0px #72727226;
          }
        }
        .table-row{
          .cell-name{
            background-color: #fff;
          }
        }
      }
    }
  }
}