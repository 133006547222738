@import 'src/styles/_colors';
@import 'src/styles/_variables';
@import 'src/styles/breakpoints';

.request-tab {
    .status-stats {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .stat-card {
            flex-grow: 1;
        }
    }
}

.request-service,
.request-startup {
  .company_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .company_name{
      font-weight: bold;
      font-size: 16px;
      color: $text-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 200px
    }
    .company_type {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 200px
    }
  }
  .company_info {
    width: 100%;
    height: 57px;
    padding-top: 8px;        
    .company_description {
      color: $text-color;
      display: block;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .request-service,
  .request-startup {
    .company_header {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      button {
        width: 100%;
      }
    }
  }
}