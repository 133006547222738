@import 'src/styles/_colors';

.toolAdminAnalytics {
    .date-range {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        .input_text {
            width: 180px;
        }
    }
}
