@import 'src/styles/_colors';
@import 'src/styles/_variables';
@import 'src/styles/mixins';

.main-breadcrumb{
  background-color: transparent;
  display: flex;
  width: 100%;
  align-items: center;
  justify-self: flex-start;
  margin-bottom: calc(#{$spacing} - 5px);
  padding: 0;
  flex-wrap: wrap;
  a,
  h4,
  .go-back{
    color: $textColor;
    font-weight: bold;
    white-space: nowrap;
    line-height: 1;
    margin-bottom: 5px;
    max-width: 600px;
    @include textEllipsis;
  }
  a,
  h4{
    svg{
      margin-right: 5px;
    }
    &:first-child{
      svg{
        display: none;
      }
    }
    &:not(:first-child){
      padding-left: 5px;
    }
    &:last-child{
      color: var(--primary-color);
    }
  }
  .go-back{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg{
      margin-right: 10px;
    }
  }
}