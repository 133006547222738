@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.messages-menu {
  background-color: white;
  position: fixed;
  right: 0;
  top: 80px;
  width: 650px;
  height: calc(100vh - 100px);
  z-index: 11;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: $borderRadius 0 0 $borderRadius;
  padding: $boxPadding 0px;
  transform: translate3d(650px, 0, 0);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
  overflow: hidden;
  padding: $boxPadding;
  .messages-menu-wrapper{
    height: calc(100% - 100px);
  }
  .panel-empty-message{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
      width: 250px;
      margin: 15px auto;
    }
  }

  &.show{
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: $breakpoint-sm) {
  .messages-menu {
    width: 90vw;
  }  
}