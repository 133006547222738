@import 'src/styles/variables';
@import 'src/styles/breakpoints';


.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  .header-info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
  .cell-keywords{
    display: inline-flex;
    flex-wrap: wrap;
    padding-top: 5px;
    div{
      line-height: 1.1;
      background-color: #E0E0E0;
      padding: 7px 10px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 12px;
    }
  }
}

.other-info{
  .output_wrapper {
    margin-bottom: 10px;
  }
}

.table-captable-details {
  width: 100%;
  td {
    border: 1px solid var(--primary-light-color);;
    padding: 10px; 
    &:first-child {
      width: 30%;
      background-color: var(--primary-light-color);
    }
  }
}

.share-stats{
  display: flex;
  justify-content: space-between;
  div {
    flex: 1;
    &:not(:first-child){
      margin-left: 20px;
    }
  }
}
