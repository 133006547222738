@import 'src/styles/_variables';
@import 'src/styles/_colors';
@import 'src/styles/mixins';

.form-body-rowKpi{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $marginListBetween;
    .form-body-rowKpi-content{
        width: 100%;
        background-color:var(--primary-light-color);
        padding: 10px 15px;
        border-radius: 10px;
        max-width: calc(100% - 60px);
        .form-body-rowKpi-content-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 7px;
            .form-body-rowKpi-content-title {
                font-size: 16px;
                font-weight: 700;
                @include textEllipsis;
                max-width: calc(100% - 100px)
            }
        }
        .form-body-rowKpi-content-description {
            display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 2; 
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .form-body-rowKpi-content-category {
            @include keywords;
            height: 20px;
            line-height: 1;
            & > div {
                display: flex;
                align-items: center;
                padding: 0 0 0 9px;
                & > span {
                    font-size: 12px;
                    font-weight: bold;
                    padding-right: 9px;
                }
                .pemission-label {
                    margin: 0;
                }
            }
        }
    }
    .form-body-rowKpi-icons {
        margin-left: $marginListBetween;
        display: flex;
        flex-direction: column;
        justify-content: center;
        svg {
            color: var(--primary-color);
            cursor: pointer;
            &.disabled {
                color: $greyLight;
                pointer-events: none;
                cursor: not-allowed;

            }
        }
    }
}

.form-button{
    margin: $marginListBetween;
    justify-content: space-around;
    display: flex;
    align-items: center;
}