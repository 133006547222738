@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.captable-select,
.operation-timeline {
    position: relative;
    .captable-select-box, 
    .timelineButton-box{
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: var(--primary-color);
        padding: 10px 15px;
        border-radius: $borderRadius 0 0 $borderRadius;
        border: 1px solid var(--primary-color);
        cursor: pointer;
        color: #fff;
        span{
            margin-left: 10px;
            font-size: 14px;
        }
        &.noDate {
            border-radius: $borderRadius;
        }
        .captable-select-box--name {
            max-width: 200px;
            @include textEllipsis;
        }
        svg {
            min-width: 14px;
        }
    }
    .timelineButton-box {
        background-color: white;
        color: var(--primary-color);
        border-radius:  0 $borderRadius $borderRadius 0;
        svg {
            height: 14px;
            width: 14px;
        }
    }
    .captable-popup, 
    .timeline-box{
        position: absolute;
        background-color: white;
        width: 325px;
        z-index: 6;
        left: 0;
        top: 0;
        border-radius: $borderRadius;
        box-shadow: $boxShadowMedium;
        .captable-popup-header,
        .timeline-box-header{
            background-color: var(--primary-color);
            display: flex;
            justify-content: flex-end;
            border-radius: $borderRadius $borderRadius 0 0;
            .closeOption{
                padding: $boxPadding;
                svg{
                    cursor: pointer;
                }
            }
        }
        .captable-popup-body,
        .timeline-box-body{
            border-bottom-right-radius: 10px;
            padding: $boxPadding;
            .timelineSelect{
                .timeline-value{
                    padding: $boxPadding;
                    background: var(--primary-light-color);
                    border-radius: 10px;
                    font-weight: bold;
                }
            }
            .dates{
                max-height: 35vh;
                overflow-y: auto;
                .row-date{
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    .row-date-left {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .date-name{
                            margin: 0;
                            line-height: 1;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 160px;
                        }
                        &.selected,
                        &:hover{
                            .date-name{
                                font-weight: bold;
                            }
                        }
                    }
                }

                .row-icon{
                    border-radius: 50%;
                    border: 1px solid var(--primary-color);
                    background-color: #fff;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .row-content{
                    padding: 0 0 0 10px;
                    .date-name{
                        margin: 0;
                        line-height: 1;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 225px;
                    }
                    .date-value{
                        color:  var(--primary-color);
                        margin: 0;
                    }
                }
            }
        }
        .timeline-box-body {
            .row-date:not(:last-child):after{
                    position: absolute;
                    content:'';
                    width: 1px;
                    height: 25px;
                    left: 30px;
                    top: 8px;
                    background-color: var(--primary-color);
                    transform: translateY(42px);
                    svg{
                        background-color: var(--primary-color);
                    }
                }
        }
    }
}
