@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.codex-editor {
  *:not(.popup) * {
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
  }
  width: 80%;
  margin: 0px auto;
  min-height: 300px;
  border: 1px solid $gray;
  box-shadow: $boxShadowLight;
  background-color: white;
  z-index: unset;
  .codex-editor__redactor{
    margin: 40px 50px;
    padding: 0 !important;
  }
  .ce-block__content, .ce-toolbar__content {
    max-width: none !important;
  }
  .simple-box{
    display: block;
    margin: 15px 0;
    .simple-box-content{
      display: inline-block;
      min-width: 200px;
      border: 1px solid #e0e0e0;
      border-radius: 7px;
      padding: 10px 12px;
    }
  }
  .cdx-attaches{
    border: 1px solid #e0e0e0;
    border-radius: 7px;
    padding: 10px 12px;
    .cdx-attaches__file-icon{
      display: none !important;
    }
  }
  .ce-header{
    font-weight: bold;
  }
  h1.ce-header{
    font-size: 32px;
  }
  h2.ce-header{
    font-size: 28px;
  }
  h3.ce-header{
    font-size: 24px;
  }
  h4.ce-header{
    font-size: 18px;
  }
  h5.ce-header{
    font-size: 16px;
  }
  .kpi-summary-box {
    cursor: pointer;
  }
  .chart {
    font-size: 12px;
  }
}

.krg-dates{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 50px;
  & > div:not(:first-child) {
    margin-left: 10px;
  }
  .input_text{
    margin: 0;
  }
  .pre_text,
  .post_text{
    display: none;
  }
}