@import 'src/styles/_colors';
@import 'src/styles/_variables';
@import 'src/styles/_mixins';

$inputsMargin: 30px;

.input-select-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: $inputsMargin;
  .pre_text{
    margin-bottom: 5px;
    color: $textColorLight;
    &.text_error {
      color: $red !important;
    }
  }
  .post_text {
    font-size: 12px;
    color: $textColorLight;
    padding-left: 15px;
    line-height: 1.5;
    margin-top: 5px;
    *{
      font-size: 14px;
      line-height: 1.5;
    }
  }
  .input_error {
    border: 1px solid $red !important;
  }
  .input-select-select {
    padding: 8.5px 7.5px;
    border: 1px solid $borderGrey;
    border-radius: $borderRadiusExtraLight;
    min-width: 70px;
    background-color: white;
    cursor: pointer;
    z-index: 5;
    .selected-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      span{
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        line-height: 1.5;
      }
      .arrow-input-select {
        margin-left: 10px;
        transition-duration: 0.5s;
        transition-property: transform;
        color: var(--primary-color);
      }
      .arrow-input-select.rotate {
        transform: rotate(180deg);
      }
    }
    &.show{
      border-radius: $borderRadiusExtraLight $borderRadiusExtraLight 0 0 !important;
    }
  }

  .icon,
  .icon-right {
    svg{
      margin: 0;
    }
  }
  .icon {
    margin-right: 10px;
  }
  .icon-right {
    margin-left: 10px;
    svg {
      margin-left: 0 !important;
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    margin-top: 10px;
    flex: 1;
    .input-select-option-selected {
      background-color: var(--primary-color);
      padding: 4px 8px 4px 13px;
      color: $white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      border-radius: 20px;
      margin: 0 5px 5px 0;
      border: 1px solid var(--primary-color);
      cursor: pointer;
      &.not-selected {
        color: var(--primary-color);
        background-color: #eee;
      }

    }
  }
  &.value-inside {
    .input-select-select{
      position: relative;
      padding: 0px 10px;
      height: auto;
      cursor: text;
      .input-select-text {
        flex: 1;
        min-width: 10%;
        background-color: inherit;
        border: none;
        height: 32px;
        margin-bottom: 4px;
      }
      .input-select-option-selected {
        padding: 5px 20px;
      }
      .selected-item {
        .selected-items {
          margin: 10px 0 5px;
          flex-wrap: wrap;
          .input-select-option-selected {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 5px;
            margin-bottom: 5px;
            max-width: none;
            svg {
              margin-left: 5px;
            }
          }
        }
        .x-input-select {
          color: var(--primary-color);
          cursor: pointer;
        }
      }
      &.no-border {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  &.disabled{
    .input-select-select  {
      cursor: default;
      background-color: #f7f7f7;
    }
    .selected-item{
      .arrow-input-select {
        display: none !important;
      }
    }
  }
}

.input-select-item-options {
  transition: opacity 0.5s ease-in-out;
  max-height: 250px;
  min-width: 70px;
  width: 100%;
  z-index: 6;
  border-bottom-left-radius: $borderRadiusExtraLight;
  border-bottom-right-radius: $borderRadiusExtraLight;
  background-color: $white;
  border: 1px solid $borderGrey;
  overflow: auto;
  margin-bottom: 25px;
  &.extra-top{
    top: 80px;
  }
  .input-select-searcher-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    height: 47px;
    z-index: 2;
    .input-select-searcher{
      position: relative;
      top: 5px;
      margin: auto;
      width: 95%;
      .input_text {
        margin: 0 0 10px 0;
        .pre_text {
          display: none;
        }
        .input {
          padding-left: 40px;
          background-color: white;
        }
      }
      .search-icon {
        color: var(--primary-color);
        position: absolute;
        top: 13px;
        left: 10px;
      }
    }
  }

  .input-select-item {
    width: 100%;
    font-size: 14px;
    padding: 5px 15px;
    color: $textColor;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.is-empty{
      cursor: not-allowed;
      justify-content: center;
      &:hover{
        background-color: transparent !important;
      }
    }
    &.selected{
      background-color: var(--primary-color) !important;
      color: $white
    }
    &.focus{
      border: 1px solid var(--primary-color);
    }
  }
  .input-select-item:hover {
    background-color: var(--primary-color-15);
  }
  .input-select-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
  }
  .icon {
    margin-right: 10px;
  }
  .empty-select {
    text-align: center;
    img {
      padding: 10px;
      max-height: 100px;
      object-fit: contain;
    }
  }
  
  .input_checkbox {
    margin-bottom: 0px;
    .pre_text {
      display: none;
    }
    .input_option, label {
      margin-bottom: 0px;
    }
    .input_option {
      padding: 6px 15px;
      .checkbox-custom {
        top: 7px;
        left: 15px;
      }
      &:hover {
        background-color: var(--primary-color-15);
      }
    }
  }
}

.inputUsers-box{
  position: relative;
  cursor: pointer;
  .inputUsers-button {
    position: absolute;
    bottom: 0px;
    right: 0px; 
    .button-app {
      width: 40px;
      height: 40px;
      border-radius: 0 $borderRadiusExtraLight $borderRadiusExtraLight 0;
    }
  }
}
.output_wrapper{
  margin-bottom: $inputsMargin;
  h6 {
    display: inline-block;
    margin-bottom: 5px;
    color: $textColorLight;
    font-size: 14px;
    line-height: 1.5;
  }
  &.pointer:hover {
    cursor: pointer;
  }
  .output_keyword{
    display: inline-flex;
    margin: 0 5px 5px 0;
    background-color: var(--primary-color);
    padding: 5px 15px;
    color: $white;
    font-size: 14px;
    border-radius: 20px;
    word-break: break-all;
    svg{
      width: 16px;
      min-width: 16px;
      height: auto;
      margin-right: 5px;
      &.delete{
        width: 24px;
        min-width: 24px;
      }
    }
  }
  .input_radio, .input_option {
  margin-bottom: 0 !important;
  }
  .radio-custom {
    position: relative !important;
  }
  &.clean {
    .output_value {
      border: none;
    }
  }
}
.output_value{
  min-height: 40px;
  position: relative;
  display: block;
  width: 100%;
  padding: 8.5px 7.5px;
  margin-bottom: $inputsMargin;
  color: $textColor;
  border: 1px solid $borderGrey;
  border-radius: $borderRadiusExtraLight;
  box-sizing: border-box;
  overflow: scroll;
  white-space: nowrap;
  background-color: #f7f7f7;
  &.textarea{
    white-space: initial;
    max-height: 250px;
  }
  &::-webkit-scrollbar {display: none;}
  *{
    word-break: break-all;
  }
  .value {
    font-size: 14px;
    line-height: 1.5;
  }
  &.symbol {
    .value {
      padding-right: 13px;
    }    
    .output_symbol{
      position: absolute;
      top: 0;
      right: 10px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.input_text,
.input_number,
.input_textarea,
.input_password,
.input_multiselect,
.input_radio,
.input_checkbox,
.input_stars,
.input_avatar,
.input_file,
.input_toggle,
.input_perms,
.input_filezone,
.input_permission,
.input_richtext {
  width: 100%;
  margin-bottom: $inputsMargin;

  .pre_text {
    display: inline-block;
    margin-bottom: 5px;
    color: $textColorLight;
    font-size: 14px;
  }

  input:not(.stype),
  select,
  textarea {
    display: block;
    width: 100%;
    padding: 8.5px 7.5px;
    font-size: 14px;
    color: $textColor;
    border: 1px solid $borderGrey;
    border-radius: $borderRadiusExtraLight;
    box-sizing: border-box;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(30,82,120,.25)!important;
    }

    &:-webkit-autofill::first-line {
      font-size: 1rem;
      font-family: 'AktivGrotesk-regular';
    }

    &:disabled {
      background-color: #f7f7f7 !important;
      cursor: default !important;
    }
  }

  .stype {
    color: red;
  }

  textarea {
    line-height: 1.2;
    height: 125px;
    resize: vertical;
  }

  .select {
    cursor: pointer;
    color: $placeholder;
    appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    background-image: url(../../assets/svg/arrow-down-short.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 16px;

    & option {
      font-family: $regular;
    }

    & option:not(:first-of-type) {
      color: $text-color;
    }

    & option:disabled {
      color: $greyLight;
    }
  }

  .input_error {
    border: 1px solid $red !important;    
  }
  &.input_radio,
  &.input_checkbox{
    .input_error {
      border-width: 0 0 0 2px !important;
      padding-left: 5px;
    }
  }
  .text_error {
    color: $red !important;
  }

  .post_text {
    font-size: 12px;
    color: $textColorLight;
    padding-left: 15px;
    line-height: 1.5;
    margin-top: 5px;
    *{
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .input-container{
    position: relative;
    &.symbol{
      input{
        padding-right: 25px;
      }
    }
    &.left-icon{
      input{
        padding-left: 35px;
      }
    }
    .input-symbol{
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      right: 14px;
      bottom: 0px;
      font-size: 14px;
      line-height: 1;
    }
    .left-icon{
      position: absolute;
      display: flex;
      align-items: center;
      left: 10px;
      font-size: 20px;
      line-height: 1;
      height: 100%;
      img {
        width: 16px;
        height: 16px;
      }

    }
  }

  .errors {
    color: $notification-color;
    font-size: 14px;
    padding-left: 15px;
    line-height: 1.5;
  }
}

.input_number {
  .input-container{
    display: flex;
    .selected-numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      margin: 2px 10px 2px 0;
      max-width: none;
      color: white;
      border-radius: $borderRadiusMedium;
      background-color: var(--primary-color);
    }
    &.symbol input {
      padding-right: 26px !important;
    }
    &.disabled {
      background-color: #f7f7f7 !important;
      .selected-numbers { 
        padding-right: 10px;
      }
    }
  }
  .input-step {
    visibility: hidden;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    bottom: 0px;
    right: 3px;
    display: flex;
    flex-direction: column;
    svg{
      z-index: 1;
      cursor: pointer;
      font-size: 9px;
      opacity: 40%;
      height: auto !important;
      &:hover {
        opacity: 100%;
      }
    }
  }
  &:hover {
    .input-step {
      visibility: initial;
    }
  }
}

.input_richtext{
  .custom-file-upload{
    height: 200px;
  }
  .rdw-editor-wrapper{
    border-radius: $borderRadiusLight;
    border: 1px solid $borderGrey;
    padding: 5px;
  
    .toolbar-class{
      border: 1px solid $borderGrey;
      padding: 6px !important;
      margin: 0 !important;
      display: flex !important;
      justify-content: flex-start;
      margin: 0 !important;
      flex-wrap: wrap;
      & > *{
        margin: 0;
        padding: 5px;
        & > *{
          // padding: 10px 0;
          margin-bottom: 0;
          margin-left: 0;
          border: none;
          // &:not(.rdw-link-modal):hover,
          &.rdw-option-active{
            background-color: var(--primary-light-color);
            box-shadow: none !important;
          }
        }
      }
    }
    .rdw-fontsize-dropdown{
      max-height: 20px
    }
  
    .rdw-editor-main{
      margin: 14px 0;

    }
    .public-DraftEditor-content{
      .public-DraftStyleDefault-block{
        margin: 0 !important;
        span{
          font-weight: inherit;
          font-size: inherit;
        }
      }
    }
    .rdw-link-modal{
      background-color: #fff !important;
      padding: 10px;
      height: auto;
      box-shadow: $boxShadowLight !important;
      label{
        font-size: 14px;
        line-height: 1;
      }
      input[type=checkbox]{
        width: auto;
        padding: 0;
        margin: 0;
        height: auto;
        display: inline;
      }
    }
  }
  &.disabled{
    .rdw-editor-toolbar{
      display: none !important;
    }
    .rdw-editor-wrapper{
      background-color: #f7f7f7;
      cursor: default;
    }
  }
}

.input_file{
  .input_file-wrapper{
    position: relative;
    margin-bottom: $inputsMargin;
  }
  .file-info{
    margin-top: 10px;
    background-color: var(--primary-color);
    padding: 10px 10px 10px 15px;
    color: $white;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border-radius: 20px;
    margin-right: 5px;
    cursor: pointer;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      word-break: break-word;
      svg{
        margin-right: 5px;
      }
    }
    svg{
      width: 16px;
      min-width: 16px;
      height: auto;
      &.delete{
        width: 24px;
        min-width: 24px;
      }
    }
  }
}

.input_password {
  .password_flex {
    display: flex;
    align-items: center;

    svg {
      height: 18px;
      margin-left: -33px;
      margin-top: -2px;
      cursor: pointer;
    }
  }
}

.input_perms{
  .pre_text{
    display: flex;
    justify-content: space-between;
  }
  .change-all:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .perms-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 10px;
    border-radius: $borderRadiusLight;
    &:not(:last-child){
      margin-bottom: 5px;
    }
    .perm-name{
      flex: 1 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg{
        color: var(--primary-color);
        margin-right: 7px;
      }
    }
    .input-select-wrapper{
      margin: 0 !important;
      width: 175px;
    }
    &:hover{
      background-color: var(--primary-color-15);
    }
  }
}

.input_toggle{
  .toggle_option{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 10px 0;
    svg{
      margin-right: 10px;
      color: $mediumGray;
    }
    &.active{
      svg{
        color: #43A047;
      }
    }
    &:last-child{
      padding-bottom: 0;
    }
  }
  &.disabled{
    .toggle_option{
      cursor: default;
      svg{
        color: $gray;
      }
    }
  }
}

.input_textarea {
  .pre_text {
    display: flex;
    justify-content: space-between;

    .right {
      color: $countColor;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.input_multiselect {
  .selected_items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;

    span {
      background: var(--primary-color);
      border-radius: 25px;
      color: $white;
      padding: 2px 21px 2px 9px;
      font-size: 14px;
      position: relative;
      margin-right: 8px;
      margin-bottom: 4px;
      line-height: 1.5;
      cursor: pointer;

      div {
        position: absolute;
        right: 8px;
        top: 1px;

        img {
          width: 5px;
          vertical-align: middle;
          border-style: none;
        }
      }
    }
  }
}

.input_avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 164px !important;
  .file-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    svg.arrow-left-file {
      transform: scale(-1, 1);
      margin-bottom: 25px;
      path {
        fill: var(--primary-color);
      }
    }
    .pre_text {
      font-size: 16px;
      font-weight: 600;
      padding-right: 5px;
    }
  }
  input[type='file'] {
    display: none;
  }
  .input_avatar_img {
    width: 100%;
    height: auto;
  }
  .file-upload-label-wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .img-wrapper {
    position: relative;
    .image-preview-label {
      width: 100px;
      min-width: 100px;
      height: 100px;
      cursor: pointer;
      border-radius: 35%;
      display: inline;
      aspect-ratio: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      box-shadow: $boxShadowLight;
      padding: 5px;
    }
    .remove-icon {
      position: absolute;
      top: -7px;
      right: -7px;
      svg {
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }
  .image-preview-label:hover {
    opacity: 0.8;
  }
  .custom-file-upload {
    cursor: pointer;
    padding: 15px;
    color: $white;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    svg.input-file-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1 !important;
      path {
        fill: var(--primary-color);
      }
    }
    svg.file-icon {
      z-index: 2;
      position: absolute;
      margin-bottom: 5px;
    }
  }
}

.input_filezone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
  input[type='file'] {
    display: none;
  }
  .input_file_img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-left: 10px;
  }
  .file-upload-label-wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .image-preview-label {
    cursor: pointer;
  }
  .image-preview-label:hover {
    opacity: 0.8;
  }
  .custom-file-upload {
    cursor: pointer;
    padding: 15px;
    background-color: white;
    border: 2px dashed #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      margin-bottom: 10px;
      width: 24px;
      height: 24px;
    }
  }
  .custom-file-upload:hover {
    background-color: #f7f7f7;
  }
  &.disabled .custom-file-upload {
    background-color: #f7f7f7 !important;
    cursor: default !important;
  }
}

.input_radio,
.input_checkbox {
  .input_option {
    position: relative;
    margin-bottom: 10px;
    input {
      cursor: pointer;
    }
  
    .stype {
      position: absolute;
      opacity: 0; 
      height: 21px;
      width: 21px;
      padding-right: 100px;
      z-index: 10;
    }
 
    .checkbox-custom,
    .radio-custom {
      position: absolute;
      top: 0px;
      left: 0;
      width: 21px;
      height: 21px;
      background-color: white; 
      border: 2px solid var(--primary-color); 
      border-radius: 50%; 
      transition: background-color 0.3s, border-color 0.3s;
      svg {
        display: none;
      }
  
      /* Cambios cuando está seleccionado */
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background-color: var(--primary-color); 
        border-radius: 50%;
        opacity: 0; 
        transition: opacity 0.3s; 
      }
    }

    .checkbox-custom {
      border-radius: 5px;
    }
  
    /* Estilos de etiquetas para los radios */
    label {
      margin-left: 30px;
      font-size: 16px;
      cursor: pointer;
    }
  
    /* Cambiar el estilo de los radios cuando están seleccionados */
    .stype:checked + .checkbox-custom, 
    .stype:checked + .radio-custom, .radio-custom.checked {
      cursor: pointer;
      background-color: var(--primary-color); 
      border-color: var(--primary-color); 
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: block;
        width: 15px;
        height: 15px;
        color: white;
        cursor: pointer;
      }
    }

    &.disabled{
      .checkbox-custom,
      .radio-custom {
        background-color: $gray;
      }
      input,
      label{
        cursor: not-allowed;
      }
    }
  }
}

.input_stars {
  .input_stars-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .input_star {
      margin: 5px;
      cursor: pointer;
    }
    &.disabled {
      .input_star {
        cursor: not-allowed;
      }
    }
  }
}

.is_required {
  color: red;
}

.output_errors {
  background-color: tomato;
  padding: 0.375rem 0.75rem;
  color: white;
  margin-bottom: 33px;
  border-radius: $borderRadius;
  span {
    display: block;
    margin: 7px 0;
  }
}

.input_slider {
  position: relative;
  height: 79px;
  .pre_text { margin: 0; }
  .slider {
    padding: 0 20px;
    position: relative;
    width: 100%;
    .slider__track,
    .slider__range {
      width: 100%;
      border-radius: 3px;
      height: 5px;
      margin: 5px 0;
    }   
    .slider__min_max {
      width: 100%;
      height: 18px;
      position: relative;
      span {
        position: absolute;
        font-size: 14px;
        &:first-child { left: -7px; }
        &:last-child { right: -7px; }
      }
    } 
    .slider__track {
      background-color: $gray;
      z-index: 1;
    }
    .slider__range {
      position: relative;
      top: -10px;
      background-color: var(--primary-color-75);
      z-index: 2;
      overflow: visible;
      &::after {
        content: '';
        position: absolute;
        top: -11px;
        right: -10px;
        background-color: var(--primary-color);
        height: 18px;
        width: 18px;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        margin-top: 4px;
        pointer-events: all;
      }
      &.interval::before {
        content: '';
        position: absolute;
        top: -11px;
        left: -9px;
        background-color: var(--primary-color);
        height: 18px;
        width: 18px;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        margin-top: 4px;
        pointer-events: all;
      }
    }
    .slider__values {
      position: relative;
      top: -10px;
      color: var(--primary-color);
      .value {
        position: absolute;
        text-align: center;
        top: 5px;
        right: 0;
        transform: translateX(50%);
        font-size: 15px;
        text-wrap: nowrap;
      }
      .input-value {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        text-align: center;
        top: -5px;
        right: 0;
        transform: translateX(50%);
        .input_number {
          margin-bottom: 0;
          max-width: 150px;
          min-width: 150px;
          .pre_text {
            display: none;
          }
          input {
            height: 10px;
            text-align: center;
          }
        }
      }
      .min-value {
        position: absolute;
        left: -7px;
        font-size: 15px;
      }
      .hide {
        display: none;
      }
    } 
    &.loading {
      .slider__range, .slider__values {
        display: none;
      }
    }
  } 
  
  .thumb {
    background-color: transparent !important;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 25px;
    height: 0;
    width: 100%;
    outline: none;
    z-index: 3;
    /* For Chrome browsers */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: transparent;
      height: 30px;
      width: 30px;
      pointer-events: all;
      cursor: pointer;

    }
    
    /* For Firefox browsers */
    &::-moz-range-thumb {
      background-color: transparent;
      height: 30px;
      width: 30px;
      pointer-events: all;
      cursor: pointer;
    }
  }
}
.input_permission{
  .input_permission-container{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-items: start;
    cursor: pointer;
    .value{
      svg {
        margin-right: 8px;
      }
    }
    .output_wrapper {
      width: 100%;
      .output_value {
        display: flex;
        align-items: center;
      }
    }
    .shared-with {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px 0 10px;
      svg{
        margin-right: 3px;
      }
    }
  }
}
