$topbarHeight: 80px;
$footerHeight: 48px;

body {
  &.embed {
    background-color: white;
    .topbar-embed .embed-topbar-section,
    .main {
      max-width: 100% !important;
    }
  }
}

#root {
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    &.impersonate {
      top: 40px
    }
  }
  .left-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 9;
  }
  .main {
    margin-top: $topbarHeight;
    padding: $spacing 50px $spacing 250px;
    max-width: $breakpoint-xl;
    min-height: calc(100vh - #{$topbarHeight} - #{$footerHeight});
    margin-left: auto;
    margin-right: auto;
    &.embed {
      background-color: white;
    }
    &.impersonate {
      margin-top: calc(#{$topbarHeight} + 40px);
    }
    &.noHeader {
      margin-top: 0;
      min-height: calc(100vh - #{$footerHeight});
      &.impersonate {
        margin-top: 40px;
      }
    }
    &.invite-founder {
      padding-bottom: 90px !important;
    }
    &.noAside {
      padding-left: 50px;
      max-width: $breakpoint-lg;
    }
    &.hideAside {
      padding-left: 125px;
    }
    &.public-form{
      min-height: calc(100vh - #{$footerHeight});
      padding: 0 !important;
      background-color: var(--primary-color);
      display: flex;
      align-items: stretch;
      justify-content: flex-end;
      max-width: 100%;
      .form-wrapper{
        background-color: $bodyColor;
        width: 45vw;
        min-width: 550px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing;
        .form-box{
          width: 100%;
          max-width: 450px;
          padding: 40px;
          position: relative;
          z-index: 1;
          .form-logo{
            display: block;
            margin: 0 auto 15px;
            max-width: 150px;
            max-height: 150px;
            width: auto;
            height: auto;
          }
          .form-header{
            text-align: center;
            margin-bottom: 20px;
          }
          .form-actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            button{
              margin-bottom: $marginListBetween;
            }
            & > * {
              cursor: pointer;
            }
          }
        }
      }
      .background-dots{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 300px;
      }
      .background-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 55vw;
        max-width: calc(100vw - 550px);
        height: 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing;
        img{
          max-width: 100%;
        }
      }
    }
  }
}

.sticky-top{
  position: sticky !important;
  top: $topbarHeight;
  z-index: 8;
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg){
  body:not(.touchscreen){
    #root {
      .main {
        padding-left: 125px;
        &.hideAside, &.noAside{
          padding-left: 50px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #root {
    .main {
      padding-left: 50px;
      &.public-form{
        .form-wrapper{
          .form-box{
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: $breakpoint-md) {
  #root {
    .main {
      padding: 15px !important;
      &.public-form{
        padding: 0 !important;
        .form-wrapper{
          padding: 15px;
          width: 100%;
          min-width: unset;
          background-color: transparent;
          .form-box{
            width: 100%;
            padding: 15px;
            &.form-box-opacity{
              background-color: rgba(255,255,255,0.95);
            }
          }
        }
        .background-image{
          width: 100vw;
          height: 100vh;
          max-width: none;
          padding: 0;
          img{
            width: 100vw;
            height: 100vh;
            object-fit: cover;
          }
        }
      }
    }
  }
}
