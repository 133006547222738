@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.mentor-card-wrapper {
  display: flex;
  box-shadow: $boxShadowLight;
  border-radius: $borderRadius;
  width: 100%;
  color: $textColor;
  .mentor-card-left-section {
    background-color: var(--primary-color);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 20px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .avatar{
      box-shadow: none;
    }
    .mentor-hours {
      color: $white;
      font-size: 20px;
      text-align: center;
      line-height: 24px;
    }
  }
  .mentor-card-right-section {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 20px;
    background-color: $white;
    width: 70%;
    .mentor-name {
      margin: 0 0 5px 0;
    }
    p.mentor-description {
      margin-bottom: 25px;
      svg{
        margin-right: 7px;
      }
    }
    .next-meeting-wrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
      svg {
        color: var(--primary-color);
      }
      .next-meeting-title {
        margin-left: 7px;
        margin-bottom: 0 !important;
      }
    }
    p.next-meeting-info {
      margin: 0 0 0 32px;
    }
  }
}