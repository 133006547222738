@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/colors';

.statutory-book-wrapper {
  .statutory-select-group {
    width: 50%;
    max-width: 350px;
    margin-bottom: 10px;
  }
  .statutory-table{
    display: flex;
    .statutory-table-left{
      flex: 0 225px;
      box-shadow: 6px 0 10px 0px #72727226;
      .statutory-table-cell{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 225px;
        text-align: center;
        &:not(:first-child){
          cursor: pointer;
        }
        &.empty {
          min-height: 54px !important;
        }
      }
    }
    .statutory-table-right{
      flex: 1 0px;
      overflow-x: auto;
      .statutory-table-row{
        display: flex;
        .statutory-table-cell{
          min-width: 130px;
          width: 100%;
          border-width: 0 0 1px 1px;
          border-style: solid;
          border-color: #ccc !important;
          text-align: center;
          &.large {
            min-width: 360px;
          }
          &.odd {
            background-color: var(--primary-color-15) !important;
          }
          .op-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
          }
        }
        &.total {
          .statutory-table-cell {
            font-weight: bold;
          }
        }
      }
    }
    .statutory-table-cell{
      min-height: 43px;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      width: 160px;
      &.porc {
        min-width: 100px !important;
      }
    }
    h6.statutory-table-cell{
      background-color: var(--primary-color);
      color: #fff;
      border: none !important;
      text-align: center;
      margin: 0;
    }
  }
}

@media (max-width: 440px) {
  .statutory-book-wrapper {
    .statutory-table{
      .statutory-table-left{
        max-width: 135px;
        .statutory-table-cell{
          max-width: 135px;
        }
      }
    }
  }
}