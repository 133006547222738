@import 'src/styles/colors';
@import 'src/styles/variables';

.documents{
  .documents-list{
    .document{
      color: red;
      margin-bottom: $marginListBetween;
      padding: 15px;
      a{
        color: $textColor;
        .document-info{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 10px;
          padding-right: 30px;
          .document-date{
            color: var(--primary-color);
            white-space: nowrap;
            padding-left: 10px;
          }
          .document-actions{
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .document-name{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: $marginListBetween;
          h6{
            flex: 1 0px;
            word-break: break-all;
          }
          svg{
            margin-right: 5px;
            color: var(--primary-color);
            width: 24px;
            flex: 0 24px;
            height: auto;
          }
        }
        .document-author{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .avatar{
            margin-right: 5px;
          }
        }
      }
      h6{
        margin-bottom: 0;
      }
    }
  }
}