@import "src/styles/variables";
@import "src/styles/colors";

.searcher-component {
  width: 100%;
  .input_searcher {
    display: flex;
    align-items: center;
    border: 1px solid $borderGrey;
    border-radius: $borderRadiusExtraLight;
    overflow: hidden;
    margin-bottom: 20px;
    .input {
      margin: 0;
      height: 35px;
      background-color: white;
      border: none;
      &:focus {
        box-shadow: none !important;
      }
      .pre_text { display: none; }
    }
    .btn-filters-mobile {
      display: inline-flex;
      height: 38px;
      min-width: 38px;
      padding: 0;
      background-color: var(--primary-color);
      svg {
        color: white;
      }
      &:hover {
        background-color: var(--primary-color) !important;
      }
    }
    .btn-filters-mobile {
      border-radius: 0;
      border: none;
    }
  }
  &.disabled {
    .input_text input {
      background-color: #f7f7f7;
      cursor: default;
    }
  }
}