@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.sign_up_password {
  margin: auto;
  padding: 16px;
  width: 100%;
  max-width: 500px;

  .title {
    text-align: center;
    margin-bottom: 32px;
  }

  .errors {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 14px;
    text-align: center;
    color: $notification-color;
  }

  .buttons {
    text-align: center;
    .continue_button {
      margin-left: 0px;
    }
  }

  .footer_messages {
    margin-top: 64px;
    text-align: center;
  }
}