@import 'src/styles/colors';
@import 'src/styles/breakpoints';

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

.app-loader{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &.hidden{
    display: none !important;
  }
  .loading {
    position: relative;
    height: 100px;
    width: 100px;
    border: 4px solid transparent;
    border-top-color: var(--primary-color);
    border-left-color: var(--primary-color);
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
    &::before{
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      content: "";
      border: 4px solid transparent;
      border-top-color: var(--primary-color-75);
      border-left-color: var(--primary-color-75);
      border-radius: 50%;
      -webkit-animation: spinBack 1s linear infinite;
      animation: spinBack 1s linear infinite;
    }
  } 
}

.container--loading {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9999999;
  background-color: $white;
  & > .loading {
    height: 125px;
    width: 125px;
  }
}

.tool--loading{
  padding: 75px 0;
  .loading {
    width: 100px;
    height: 100px;
    &::before{
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
}

.panel--loading{
  padding: 50px 0;
  .loading {
    width: 85px;
    height: 85px;
    &::before{
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
    }
  }
}

.dialog--loading{
  padding: 25px 0;
  .loading {
    width: 50px;
    height: 50px;
    border-width: 3px;
    &::before{
      border-width: 3px;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
    }
  }
}

.skeletonBarChart {
  position: relative;
  margin: 15px auto 0;
  width: 50%;
  border-left: 1px solid $borderDarkGrey;
  border-bottom: 1px solid $borderDarkGrey;
  span {
    position: absolute;
    &.chart1{
      top: 45%;
      left: 8%;
    }
    &.chart2{
      top: calc(75% + 1px);
      left: 16%;
    }
    &.chart3{
      top: calc(15% - 1px);
      left: 24%;
    }
    &.chart4{
      top: 35%;
      left: 32%;
    }
    &.chart5{
      top: calc(65% - 1px);
      left: 40%;
    }
    &.chart6{
      top: -5%;
      left: 48%;
    }
    &.chart7{
      top: 55%;
      left: 56%;
    }
    &.chart8{
      top: calc(75% + 1px);
      left: 64%;
    }
    &.chart9{
      top: calc(25% - 1px);
      left: 72%;
    }
    &.chart10{
      top: 55%;
      left: 80%;
    }
    &.chart11{
      top: 45%;
      left: 88%;
    }
  }
  &.skeletonBarChart-md {
    width: 65%;
    span {
      position: absolute;
      &.chart1{
        left: 5%;
      }
      &.chart2{
        left: 15%;
      }
      &.chart3{
        left: 25%;
      }
      &.chart4{
        left: 35%;
      }
      &.chart5{
        left: 45%;
      }
      &.chart6{
        left: 55%;
      }
      &.chart7{
        left: 65%;
      }
      &.chart8{
        left: 75%;
      }
      &.chart9 {
        left: 85%;
      }
      &.chart10{
        left: 95%;
      } 
      &.chart11 {
        display: none;
      }
    }
  }
  &.skeletonBarChart-sm {
    width: 80%;
    span {
      &.chart1{
        left: 5%;
      }
      &.chart2{
        left: 17%;
      }
      &.chart3{
        left: 29%;
      }
      &.chart4{
        left: 41%;
      }
      &.chart5{
        left: 53%;
      }
      &.chart6{
        left: 65%;
      }
      &.chart7{
        left: 77%;
      }
      &.chart8{
        left: 89%;
      }
      &.chart9, &.chart10, &.chart11 {
        display: none;
      }
    }
  }
}

.doughnutChart-wrapper {
  position: relative;
  margin: 30px auto;
  width: 100%;
  .white-circle {
    position: absolute;
    left: 10%;
    bottom: 10%;
    height: 80%;
    width: 80%;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
  }
}