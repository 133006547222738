@import 'src/styles/breakpoints';

.internal-form {
    .question-grid{
        .question-grid--title {
            margin-bottom: 5px;
            font-weight: 600;
            line-height: 1.5;
        }
        .question-grid--inputs {
            display: grid;
            grid-auto-rows: auto;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            .question-wrapper > * {
                .pre_text {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .internal-form {
        .question-grid{
            .question-grid--inputs {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
  }