@import "src/styles/_colors";
@import "src/styles/_variables";

.avatar{
  border-radius: 35%;
  aspect-ratio: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: $boxShadowLight;
  &.default{
    background-color: var(--primary-color);
    div {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 50%;
      color: white;
    }
  }
  &.shadowNone{
    box-shadow: none;
  }
  img {    
    width: 100%;
    padding: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    margin: auto;
  }
  &.default{
    img{
      width: 75%;
    }
  }
}

.loader-avatar{
  border-radius: 35% !important;
}