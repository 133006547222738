@import "src/styles/colors";
@import "src/styles/breakpoints";

.pagination-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  .page-item {
    span {
      width: 38px;
      background-color: white;
      border-color: $borderGrey;
      color: $textColor;
      padding: 8px;
      font-size: 16px;
      text-align: center;
      border-radius: 0 !important;
      cursor: pointer;
      &:hover {
        background-color: var(--primary-color-25);
      }
    }
    &.active {
      span {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        color: white;
      }
    }
    &.first {
      margin-right: 5px;
    }
    &.last {
      margin-left: 5px;
    }
    &.disabled span {
      color: $gray;
    }
  }
}

@media (max-width: 440px) {
  .pagination-grid {
    .page-item {
      span {
        width: 30px;
      }
    }
  }
}
