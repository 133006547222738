@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.attach-button {
  position: absolute;
  top: 28px;
  right: 16px;
}

.document-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary-color);
  span {
    font-weight: 500;
  }
}

.reporting-add{
  .output_value {
    background: var(--primary-light-color);
  }
  .attached-documents {
    display: flex;
    flex-wrap: wrap;
    .attached-document {
      background-color: var(--primary-color);
      padding: 7px 20px;
      font-size: 14px;
      border-radius: 20px;
      margin: 0 5px 5px 0;
      a{
        color: $white;
      }
    }
  }
  .background-light {
    background-color: var(--primary-light-color);
    padding: 40px 0px;
    border-radius: $borderRadiusLight;
  }
}

.preview-email-report{
  table {
    width: 100%;
    td {
      border: 1px solid var(--primary-light-color);;
      padding: 10px; 
    }
    .first-column {
      width: 30%;
      background-color: var(--primary-light-color);
    }
  }
}


.message .output_value {
  min-height: 200px;
}

iframe {
  margin: 20px 0; 
  width: 100%;
  height: 400px;
  border: none;
}

.popup-content{
  position: relative;
  .loading-iframe {
    position: absolute;
    width: 100%;
    top: 250px;
    & > * {
      margin: 0 auto;
    }
  }
}
