@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.reporting-templates-tool {
  .templates-container {
    .template {
      @include box;
      overflow:hidden;
      width: 100%;
      background: $white;
      box-shadow: $boxShadowLight;
      padding: $boxPadding;
      height: 210px;
      cursor: pointer;
      &.blank {
        width: calc(20% - 15px);
      }
      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-light-color);
        border-radius: $borderRadiusLight;
        height: 150px;
        img {
          max-height: 140px;
          max-width: 100%;
        }
        .no-image{
          font-weight: 600;
          color: $text-color;
        }
      }
      .template-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        color: $text-color;
        .template-name{
          font-weight: bold;
          margin: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: calc(100% - 20px);
        }
        .buttons-dots{
          position: relative;
          top: -1px;
          left: 7px;
        }
      }
      &:hover {
        box-shadow: $boxShadow;
      }
    }
    .separator{
      position: relative;
      display: flex;
      margin: 25px 0;
      align-items: center;
      justify-content: center;
      span{
        font-size: 12px;
        color: $greyLight;
        line-height: 1;
        margin: 0;
        padding: 0 15px;
        background-color: #fff;
        z-index: 1;
      }
      &::after{
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: 6px;
        height: 1px;
        background-color: $greyLight;
      }
    }
  }
}

@media (max-width: 1279px) {
  .reporting-templates-tool {
    .templates-container {
      .template.blank{
        width: 32.5% !important;
      }
    }
  }
}

@media (max-width: 959px) {
  .reporting-templates-tool {
    .templates-container {
      .template.blank{
        width: 49.5% !important;
      }
    }
  }
}

@media (max-width: 599px) {
  .reporting-templates-tool {
    .templates-container {
      .template.blank{
        width: 100% !important;
      }
    }
  }
}