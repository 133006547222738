@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.meeting{
  display: flex;
  box-shadow: $boxShadowLight;
  border-radius: $borderRadius;
  cursor: pointer;
  .meeting-date,
  .meeting-info{
    padding: 20px;
  }
  .meeting-date{
    padding-top: 30px;
    width: 80px;
    background-color: var(--primary-color);
    border-radius: $borderRadius 0 0 $borderRadius;    
    position: relative;    
    *{
      color: #fff;
      text-align: center;
      word-break: break-all;
    }
    &.success-bg{
      background-color: $green;
    }
    .month{
      margin-top: 15px;
    }
    h5{
      line-height: 1;
    }
  }
  .meeting-info{
    position: relative;
    flex: 1 0px;
    border-radius: 0 $borderRadius $borderRadius 0;
    .meeting-actions{
      position: absolute; 
      top: 10px;
      right: 10px;
    }
    .meeting-user{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .avatar{
        margin-right: 7px;
      }
      h6 {
        margin-bottom: 0;
      }
    }
    .meeting-text{
      margin-bottom: 10px;
      max-height: 42px;
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .meeting-time{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg{
        margin-right: 5px;
        color: var(--primary-color);
      }
      div{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg:last-child{
          margin-left: 10px;
        }
        &:not(:last-child){
          margin-left: 5px;
        }
      }
    }
  }
}

.meeting-form{
  .rdw-editor-wrapper{
    .rdw-editor-main{
      min-height: 200px;
    }
  }
  .hoursIndicator{
    display: flex;
    border: 1px solid var(--primary-color-25);
    border-radius: $borderRadiusLight;
    & > *{
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        margin-right: 5px;
      }
      &:nth-child(2){
        border-width: 0 1px 0 1px;
        border-color: var(--primary-color-25);
        border-style: solid;
      }
    }
  }
}

.meetings-list {
  .meeting {
    margin-bottom: $marginListBetween;
  }
}