@import "src/styles/colors";
@import "src/styles/variables";

.startupPlans-list {
  .box {
    margin-bottom: $marginListBetween;
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color-15) !important;
      }
    }
    span {
      display: block;
    }
  }
}

.no-results {
  text-align: center;
  padding: 35px 0;
  img{
    margin-bottom: 15px;
    width: 350px;
    max-width: calc(100% - 50px);
  }
}