.uploading-file {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 15px;
  border: 1px solid #c9c9c9;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  width: 250px;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg{
    width: 18px;
    height: auto;
    color: var(--primary-color);
  }
  .uf-info{
    flex: 1 0px;
    padding-left: 10px;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 175px;
    }
  }
}