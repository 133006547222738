@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.performanceAdd-wrapper{
  .tab-templates{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .tab-templates-left{
      width: 350px;
      position: sticky;
      top: $stickyTop;
      ul{
        max-height: 50vh;
        overflow-y: auto;
        li{
          margin-bottom: $marginListBetween;
          color: $textColorLight;
          cursor: pointer;
          svg{
            color: $textColorLight;
            margin-right: 10px;
          }
          &:hover,
          &.active{
            color: var(--primary-color);
            svg{
              color: var(--primary-color);
            }
          }
        }
      }
    }
    .tab-templates-right{
      flex: 1 0px;
      padding-left: $spacing;
      .kpi-template-details{
        .box{
          position: relative;
          &.used{
            opacity: 0.75;
          }
          .ktd-related{
            background-color: var(--primary-color);          
            color: #fff;
            padding: 7px 15px;
            border-radius: 15px;
            display: inline-flex;
            margin-right: 10px;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: $boxPadding;
            right: $boxPadding;
            svg{
              margin-right: 5px;
            }
          }
          .ktd-description{
            padding: $boxPadding;
            background-color: var(--primary-color-15);
            border-radius: $borderRadius;
          }
          &:not(:last-child){
            margin-bottom: $marginListBetween;
          }
        }
      }
    }
    .noTemplate{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        max-height: 300px;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: $breakpoint-sm){
  .performanceAdd-wrapper{
    .tab-templates{
      .tab-templates-right{
        padding-left: 0;
      }
    }
  }
}