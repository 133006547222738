@import 'src/styles/colors';
@import 'src/styles/variables';

.permissions-info {
  margin-bottom: 30px;
  ul {
    padding-left: 15px;
    li {
      display: flex;
      align-items: center;
      margin: 3px 0;
    }
    svg {
      width: 14px;
    }
  }
}

.form_shareholder {
  .input_toggle  {
    margin-bottom: 10px;
    .toggle_option{
      padding: 0;
    }
  }
  .users {
    padding: 15px;
    margin: 0px 0px 15px 0px;
    border-radius: $borderRadius;
    background-color: white;
    box-shadow: $boxShadow;
    .trash-icon {
      position: absolute;
      top: 9px;
      right: 0px;
      width: 18px;
      cursor: pointer;
    }
    .new-user {
      position: relative;
    }
  }
}

.syndicates-tab {
  .cell-content p {
    margin: 0;
  }
}

.syndicate-name {
  p {
    margin: 0;
  }
  padding: $boxPadding;
  margin-bottom: $marginListBetween;
}