@import 'src/styles/_colors';
@import 'src/styles/variables';

.reporting-new-template {
  .title {
    color: $textColor;
    font-weight: 600;
  }
  .editor-wrapper {
    margin-top: 5px;
    background-color: var(--primary-light-color);
    padding: 40px 0px;
    border-radius: $borderRadiusLight;
  }
}