@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.myInvestments-opportunities {
  .startup {
    margin-bottom: $marginListBetween;
    @include box;
    overflow:hidden;
    width: 100%;
    background: $white;
    box-shadow: $boxShadowLight;
    padding: $boxPadding;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
      box-shadow: $boxShadowMedium;
      background-color: var(--primary-light-color);
    }
    .company_header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      .company_info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        gap: 5px;
        .company_name{
          line-height: 1;
          font-weight: bold;
          font-size: 16px;
          color: $text-color;
        }
        .company_legal_name {
          line-height: 1;
          font-size: 12px;
          color: $textColorLight;
        }
        .company_country{
          display: flex;
          align-items: center;
          line-height: 1;
          font-size: 12px;
          color: $textColorLight;
          svg {
            position: relative;
            top: -0px;
            font-size: 10px;
            margin-right: 5px;
          }
        }
      }
    }
    .investment-amount {
      display: flex;
      align-items: center;
      gap: 7px;
      color: $green;

      span {
        font-size: 14px;
        font-weight: bold;
        border-radius: 12px;
        background-color: $green;
        padding: 3px 15px;
        color: white;
        font-weight: 560;
      }
    }
    .company_social {
      margin-bottom: 5px;
      img {
        margin-right: 3px;
      }
    }
    .company_description { 
      height: 45px;       
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: $text-color;
      font-size: 14px;
      margin-bottom: 5px;

    }
    .company_fundraise {
      border-top: 1px solid $borderLightGrey;
      padding-top: 10px;
      height: 55px;
      .company_fundraise_seed{
        display: flex;
        align-items: center;
      }
      .round-keyword {
        background-color: #E0E0E0;
        padding: 2px 10px;
        font-size: 12px;
        margin-left: 10px;
        font-weight: bold;
        border-radius: 12px;
      }
    }

  }
}



