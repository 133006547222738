@import 'src/styles/colors';
@import 'src/styles/variables';

.stat-card{
  padding: 15px;
  text-align: center;
  margin-bottom: $spacing;
  position: relative;
  &.with-icon{
    padding-left: 50px;
    padding-right: 50px;
  }
  &.pointer {
    cursor: pointer;
  }
  .stat-card-value{
    & > span{
      margin: 0 auto;
    }
  }
  .stat-card-title{
    margin-bottom: 0;
    line-height: 1.2;
  }
  .stat-card-diff{
    display: block;
    font-size: 14px;
    color: $mediumGray;
    margin-bottom: 5px;
  }
  .stat-card-subtitle{
    margin: 5px 0 0 0;
    font-size: 14px;
  }
  .stat-card-icon{
    margin: 5px 0 0 0;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 15px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    svg{
      width: 22px;
      height: auto;
    }
  }
}