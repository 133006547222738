@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.reporting-info{
  .recipients {
    cursor: pointer;
  }
}

.report-visualizer{
  margin-top: 5px;
  background-color: var(--primary-light-color);
  padding: 40px 0px;
  border-radius: $borderRadiusLight;
  .report-visualizer-content{
    width: 80%;
    margin: 0px auto;
    min-height: 300px;
    border: 1px solid $gray;
    box-shadow: $boxShadowLight;
    background-color: white;
    padding: 50px 40px;
  }
}