@import 'src/styles/variables';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.btn.button-app {
  min-width: 115px;
  border-radius: $borderRadiusLight;
  text-align: center;
  cursor: pointer;
  position: relative;
  outline: none;
  line-height: 1;
  transition: all 0.5s;
  outline: none;
  padding: 11px 25px;
  &,
  & > span {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }

  &:active,
  &:focus,
  &:visited {
    outline: none;
    box-shadow: $boxShadow;
  }

  &.btn-sm {
    padding-top: 7px;
    padding-bottom: 7px;
    & > span {
      font-size: 14px;
      text-transform: none;
    }
  }

  &.btn-primary,
  &.btn-primary:disabled {
    background: var(--primary-color);
    border-color: var(--primary-color);
    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: var(--primary-color) !important;
      opacity: 0.9;
    }
    svg{
      color: #fff !important;
    }
  }
  &.btn-secondary,
  &.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: white;
    border-radius: 5px;
    letter-spacing: 0.96px;
    box-shadow: 0px 3px 6px #0000000f;
    svg{
      color: var(--primary-color);
    }

    &:hover{
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color-15) !important;
      span{
        color: var(--primary-color) !important;
      }
    }
    &:active {
      background: white;
    }
    &.onBgColor{
      &:hover{
        background-color: white !important;
        opacity: 0.9;
      }
    }
  }
  &.btn-transparent{
    background-color: transparent;
    &:hover{
      background-color: var(--primary-color-15) !important;
    }
  }

  &.btn-danger,
  &.btn-danger svg{
    color: #fff !important;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &.loading {
    cursor: progress;
  }
  &[class*='-outline'] {
    background-color: white;
  }

  &.btn-icon{
    padding-left: 40px !important;
    & > svg.icon-left {
      width: 20px;
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  &.btn-icon-right{
    padding-right: 40px !important;
    & > svg.icon-right {
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  &.btn-icon-square{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    padding: 0 !important;
    position: relative;
    span {
      display: none;
    }
    svg {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      margin: 0;
      width: 24px;
      height: auto;
    }
  }
  
  .btn-loader {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    right: 0;
    height: 100%;
    & > span {
      height: 100%;
      z-index: -1;
      opacity: 0.1;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px
}

.buttons-column {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-items: stretch;
  & > .btn:not(:first-child) {
    margin-top: 15px;
  }
}

.buttons-dots{
  position: relative;
  .buttons-dots-menu{
    display: none;
    position: absolute;
    top: -4px;
    right: -5px;
    min-width: 150px;
    z-index: 5;
    padding: 5px;
    border-radius: $borderRadiusLight;
    background-color: white;
    box-shadow: $boxShadow;
    ul{
      padding-right: 25px;
      li{
        padding: 10px;
        border-radius: $borderRadiusLight;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        a{
          color: $textColor;
          width: 100%;
        }
        svg{
          margin-right: 5px;
        }
        &:hover{
          background-color: var(--primary-color-15);
          color: var(--primary-color);
          cursor: pointer;
          a{
            color: var(--primary-color);
          }
        }
        &.disabled{
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
  .bt-open,
  .bt-close{
    cursor: pointer;
  }
  .bt-open{
    color: var(--primary-color);    
  }
  .bt-close{
    color: #11B3C2;
  }

  &.show{
    .buttons-dots-menu{
      .bt-close{
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
      display: block;
    }
  }
}

.buttons-dots-menu{
  .bt-close{
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
      color: #11B3C2;
      &.bottom {
        top: auto;
        bottom: 15px;
      }
    }
    display: block;
}
.buttons-dots-menu{
  position: absolute;
  top: -4px;
  right: -5px;
  min-width: 150px;
  z-index: 7;
  padding: 5px;
  border-radius: $borderRadiusLight;
  background-color: white;
  box-shadow: $boxShadow;
  ul{
    padding-right: 25px;
    li{
      padding: 10px;
      border-radius: $borderRadiusLight;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      &.bdl-link{
        padding: 0;
        a{
          padding: 10px;
        }
      }
      a{
        color: $textColor;
        width: 100%;
      }
      svg{
        margin-right: 7px;
      }
      &:hover{
        background-color: var(--primary-color-15);
        color: var(--primary-color);
        cursor: pointer;
        a{
          color: var(--primary-color);
        }
      }
      &.disabled{
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.button-dropdown-menu{
  display: block;
  height: fit-content;
  background-color: #fff;
  padding: 10px;
  z-index: 5;
  box-shadow: $boxShadowLight;
  &.align-left{
    right: unset;
    left: 0
  }
  .button-dropdown-item{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 8px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 60vw;
    color: $text-color;
    svg{
      vertical-align: middle;
      color: $text-color;
    }
    &:hover{
      background-color: $lightGrey;
    }
  }
}


@media (min-width: $breakpoint-sm){
  .btn.button-app {
    &.btnIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      border-radius: 50% !important;
      padding: 0 !important;
      position: relative;
      span {
        display: none;
        position: absolute;
        right: 53px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 24px;
        box-shadow: $boxShadow;
        padding: 5px;
        color: $textColor;
        text-transform: none !important;
        line-height: 1;
        white-space: nowrap;
        z-index: 10;
        background-color: white;
      }
      svg {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin: 0;
        width: 24px;
        height: auto;
      }
      &:hover{
        span{
          display: block !important;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-md){
  .btn.button-app{
    &.btn-md-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      height: 38px;
      padding: 0 !important;
      position: relative;
      span {
        display: none;
      }
      svg {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin: 0;
        width: 24px;
        height: auto;
      }
    }
  }
}

@media (max-width: $breakpoint-sm){
  .btn.button-app{
    &.btn-sm-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      height: 38px;
      padding: 0 !important;
      position: relative;
      span {
        display: none;
      }
      svg {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin: 0;
        width: 24px;
        height: auto;
      }
    }
  }
}